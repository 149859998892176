import React from 'react'

const FilterIcon = () => {
  return (
  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.801751 12.5391C0.359001 12.5391 0 12.1718 0 11.7188C0 11.2981 0.309522 10.9514 0.708244 10.9039L0.801751 10.8984H9.58599C9.58599 9.53934 10.6629 8.4375 11.9912 8.4375H13.5947C14.9231 8.4375 16 9.53934 16 10.8984V12.5391C16 13.8982 14.9231 15 13.5947 15H11.9912C10.6629 15 9.58599 13.8982 9.58599 12.5391L0.801751 12.5391ZM13.5947 10.0781H11.9912C11.5801 10.0781 11.2412 10.3948 11.1949 10.8028L11.1895 10.8984V12.5391C11.1895 12.9598 11.499 13.3065 11.8977 13.3539L11.9912 13.3594H13.5947C14.0059 13.3594 14.3448 13.0427 14.3911 12.6348L14.3965 12.5391V10.8985C14.3965 10.4778 14.087 10.1311 13.6883 10.0836L13.5947 10.0781ZM4.00875 0C5.3371 0 6.41401 1.10184 6.41401 2.46094H15.1982C15.641 2.46094 16 2.82825 16 3.28125C16 3.70193 15.6905 4.04864 15.2918 4.09608L15.1982 4.10157H6.41401C6.41401 5.46067 5.3371 6.56251 4.00875 6.56251H2.40525C1.07691 6.56251 0 5.46067 0 4.10157V2.46095C0 1.10185 1.07691 8.90626e-06 2.40525 8.90626e-06L4.00875 0ZM4.00875 1.64063H2.40525C1.99409 1.64063 1.65523 1.95731 1.60886 2.36527L1.60349 2.46094V4.10157C1.60349 4.52225 1.91301 4.86896 2.31174 4.91639L2.40524 4.92189H4.00875C4.41991 4.92189 4.75877 4.6052 4.80514 4.19725L4.81051 4.10157V2.46095C4.81051 2.04027 4.50098 1.69356 4.10226 1.64612L4.00875 1.64063Z" fill="black"/>
  </svg>
  )
}

export default FilterIcon;