import React from 'react'

const  Logo = () =>{
  return (
    <svg width="336" height="105" viewBox="0 0 336 105" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M33.4435 68.8955C51.9138 68.8955 66.8869 53.9223 66.8869 35.452C66.8869 16.9817 51.9138 2.00854 33.4435 2.00854C14.9731 2.00854 0 16.9817 0 35.452C0 53.9223 14.9731 68.8955 33.4435 68.8955Z" fill="black"/>
      <path d="M33.4435 66.8869C51.9138 66.8869 66.8869 51.9138 66.8869 33.4435C66.8869 14.9731 51.9138 0 33.4435 0C14.9731 0 0 14.9731 0 33.4435C0 51.9138 14.9731 66.8869 33.4435 66.8869Z" fill="#704DE6"/>
      <path d="M101.234 69.8496C119.705 69.8496 134.678 54.8764 134.678 36.4061C134.678 17.9358 119.705 2.96265 101.234 2.96265C82.7639 2.96265 67.7908 17.9358 67.7908 36.4061C67.7908 54.8764 82.7639 69.8496 101.234 69.8496Z" fill="black"/>
      <path d="M101.234 67.8913C119.705 67.8913 134.678 52.9182 134.678 34.4479C134.678 15.9775 119.705 1.00439 101.234 1.00439C82.7639 1.00439 67.7908 15.9775 67.7908 34.4479C67.7908 52.9182 82.7639 67.8913 101.234 67.8913Z" fill="#8466E7"/>
      <path d="M168.121 69.8496C186.592 69.8496 201.565 54.8764 201.565 36.4061C201.565 17.9358 186.592 2.96265 168.121 2.96265C149.651 2.96265 134.678 17.9358 134.678 36.4061C134.678 54.8764 149.651 69.8496 168.121 69.8496Z" fill="black"/>
      <path d="M168.121 67.8913C186.592 67.8913 201.565 52.9182 201.565 34.4479C201.565 15.9775 186.592 1.00439 168.121 1.00439C149.651 1.00439 134.678 15.9775 134.678 34.4479C134.678 52.9182 149.651 67.8913 168.121 67.8913Z" fill="#A18AF2"/>
      <path d="M235.058 69.8496C253.529 69.8496 268.502 54.8764 268.502 36.4061C268.502 17.9358 253.529 2.96265 235.058 2.96265C216.588 2.96265 201.615 17.9358 201.615 36.4061C201.615 54.8764 216.588 69.8496 235.058 69.8496Z" fill="black"/>
      <path d="M235.058 67.8913C253.529 67.8913 268.502 52.9182 268.502 34.4479C268.502 15.9775 253.529 1.00439 235.058 1.00439C216.588 1.00439 201.615 15.9775 201.615 34.4479C201.615 52.9182 216.588 67.8913 235.058 67.8913Z" fill="#C7B9F7"/>
      <path d="M301.945 69.8496C320.416 69.8496 335.389 54.8764 335.389 36.4061C335.389 17.9358 320.416 2.96265 301.945 2.96265C283.475 2.96265 268.502 17.9358 268.502 36.4061C268.502 54.8764 283.475 69.8496 301.945 69.8496Z" fill="black"/>
      <path d="M301.945 67.8913C320.416 67.8913 335.389 52.9182 335.389 34.4479C335.389 15.9775 320.416 1.00439 301.945 1.00439C283.475 1.00439 268.502 15.9775 268.502 34.4479C268.502 52.9182 283.475 67.8913 301.945 67.8913Z" fill="#DED7F5"/>
      <path d="M19.3329 29.9285L12.6041 28.7233C13.3573 26.0117 14.6629 24.0031 16.5209 22.7477C18.3788 21.4421 21.0905 20.7893 24.706 20.7893C28.0202 20.7893 30.4807 21.191 32.0876 21.9443C33.6945 22.7477 34.8495 23.7018 35.5023 24.907C36.1551 26.1121 36.5066 28.3216 36.5066 31.5354L36.4062 40.1725C36.4062 42.633 36.5066 44.4408 36.7577 45.5957C37.0088 46.7507 37.4607 48.0061 38.0633 49.3619H30.782C30.5812 48.8597 30.3301 48.1567 30.079 47.2026C29.9786 46.7507 29.8782 46.4996 29.8279 46.349C28.5726 47.5541 27.2167 48.5082 25.7605 49.1108C24.3043 49.7134 22.7978 50.0147 21.1407 50.0147C18.2784 50.0147 16.0187 49.2112 14.3616 47.6546C12.7045 46.0979 11.8508 44.1395 11.8508 41.7291C11.8508 40.1725 12.2526 38.7664 13.0058 37.511C13.759 36.2557 14.8135 35.3518 16.1694 34.6488C17.5252 33.996 19.4836 33.3934 22.0446 32.9414C25.5094 32.2886 27.9198 31.6861 29.2254 31.1337V30.3804C29.2254 28.9744 28.8739 27.9701 28.1708 27.3173C27.4678 26.7147 26.1622 26.4134 24.2038 26.4134C22.8982 26.4134 21.8437 26.6645 21.1407 27.1667C20.3874 27.719 19.7849 28.6229 19.3329 29.9285ZM29.2254 35.9041C28.2713 36.2054 26.7648 36.6072 24.706 37.0591C22.6471 37.511 21.2913 37.9128 20.6887 38.3647C19.7346 39.0677 19.2325 39.9214 19.2325 40.9759C19.2325 42.0304 19.6342 42.8841 20.3874 43.6373C21.1407 44.3906 22.145 44.7923 23.3502 44.7923C24.706 44.7923 25.9614 44.3403 27.1665 43.4867C28.0704 42.8339 28.673 41.9802 28.9241 41.0261C29.1249 40.3733 29.2254 39.1681 29.2254 37.4106V35.9041Z" fill="black" stroke="white" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M43.7878 17.6256V10.7461H51.1695V17.5754H43.7878V17.6256ZM43.7878 49.3617V21.3918H51.1695V49.3617H43.7878Z" fill="black" stroke="white" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M121.019 49.3618H112.533L109.168 40.5741H93.7521L90.5885 49.3618H82.303L97.3174 10.7964H105.553L121.019 49.3618ZM106.657 34.0963L101.335 19.7849L96.1122 34.0963H106.657Z" fill="black" stroke="white" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M222.254 29.9285L215.525 28.7233C216.278 26.0117 217.583 24.0031 219.441 22.7477C221.299 21.4421 224.011 20.7893 227.627 20.7893C230.941 20.7893 233.401 21.191 235.008 21.9443C236.615 22.7477 237.77 23.7018 238.423 24.907C239.076 26.1121 239.427 28.3216 239.427 31.5354L239.327 40.1725C239.327 42.633 239.427 44.4408 239.678 45.5957C239.929 46.7507 240.381 48.0061 240.984 49.3619H233.652C233.452 48.8597 233.2 48.1567 232.949 47.2026C232.849 46.7507 232.749 46.4996 232.698 46.349C231.443 47.5541 230.087 48.5082 228.631 49.1108C227.175 49.7134 225.668 50.0147 224.011 50.0147C221.149 50.0147 218.889 49.2112 217.232 47.6546C215.575 46.0979 214.721 44.1395 214.721 41.7291C214.721 40.1725 215.123 38.7664 215.876 37.511C216.629 36.2557 217.684 35.3518 219.04 34.6488C220.396 33.996 222.354 33.3934 224.915 32.9414C228.38 32.2886 230.79 31.6861 232.096 31.1337V30.3804C232.096 28.9744 231.744 27.9701 231.041 27.3173C230.338 26.7147 229.033 26.4134 227.074 26.4134C225.769 26.4134 224.714 26.6645 224.011 27.1667C223.308 27.719 222.705 28.6229 222.254 29.9285ZM232.146 35.9041C231.192 36.2054 229.685 36.6072 227.627 37.0591C225.568 37.511 224.212 37.9128 223.609 38.3647C222.655 39.0677 222.153 39.9214 222.153 40.9759C222.153 42.0304 222.555 42.8841 223.308 43.6373C224.061 44.3906 225.066 44.7923 226.271 44.7923C227.627 44.7923 228.882 44.3403 230.087 43.4867C230.991 42.8339 231.594 41.9802 231.845 41.0261C232.046 40.3733 232.146 39.1681 232.146 37.4106V35.9041Z" fill="black" stroke="white" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M246.708 17.6256V10.7461H254.09V17.5754H246.708V17.6256ZM246.708 49.3617V21.3918H254.09V49.3617H246.708Z" fill="black" stroke="white" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M297.828 49.3617V17.2741H286.378V10.7461H317.01V17.2741H305.611V49.3115H297.828V49.3617Z" fill="black" stroke="white" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M156.572 49.3617V10.7461H169.075C173.796 10.7461 176.909 10.947 178.315 11.3487C180.525 11.9513 182.383 13.2066 183.889 15.1148C185.395 17.0732 186.149 19.5338 186.149 22.6471C186.149 25.0073 185.697 27.0159 184.843 28.6228C183.989 30.2297 182.885 31.4851 181.579 32.4391C180.273 33.343 178.918 33.9456 177.562 34.2469C175.704 34.5984 172.992 34.7993 169.477 34.7993H164.405V49.3617H156.572ZM164.355 17.2741V28.2211H168.623C171.687 28.2211 173.745 28.0202 174.8 27.6185C175.855 27.2167 176.658 26.5639 177.21 25.7103C177.813 24.8566 178.114 23.8523 178.114 22.6974C178.114 21.2913 177.712 20.1364 176.859 19.2325C176.055 18.3286 175.001 17.726 173.745 17.5252C172.791 17.3745 170.933 17.2741 168.121 17.2741H164.355Z" fill="black" stroke="white" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M5.52374 104.548V90.488H0.502197V87.6257H13.9599V90.488H8.93839V104.548H5.52374Z" fill="white"/>
      <path d="M16.1694 104.548V87.6257H23.3502C25.158 87.6257 26.4636 87.7764 27.267 88.0777C28.0705 88.379 28.7233 88.9313 29.2254 89.6846C29.7276 90.4378 29.9787 91.3417 29.9787 92.346C29.9787 93.6014 29.6272 94.6559 28.8739 95.5096C28.1207 96.313 27.016 96.8654 25.5095 97.0662C26.2627 97.5182 26.8653 97.9701 27.3173 98.4723C27.8194 98.9744 28.422 99.8783 29.2757 101.234L31.3345 104.548H27.267L24.8065 100.883C23.9528 99.577 23.3502 98.7233 22.9987 98.422C22.6974 98.0705 22.3459 97.8697 21.9944 97.719C21.6429 97.5684 21.0403 97.5182 20.2871 97.5182H19.5841V104.599H16.1694V104.548ZM19.5841 94.8065H22.0949C23.752 94.8065 24.7563 94.7563 25.158 94.6057C25.5597 94.455 25.861 94.204 26.1121 93.9027C26.3632 93.5512 26.4636 93.1494 26.4636 92.6473C26.4636 92.0949 26.3129 91.643 26.0117 91.2915C25.7104 90.94 25.2584 90.7391 24.7061 90.6387C24.4048 90.5884 23.6013 90.5884 22.1953 90.5884H19.5841V94.8065Z" fill="white"/>
      <path d="M48.4579 104.548H44.742L43.2857 100.732H36.5066L35.1006 104.548H31.4851L38.0633 87.6257H41.6788L48.4579 104.548ZM42.1308 97.8697L39.8209 91.5927L37.5612 97.8697H42.1308Z" fill="white"/>
      <path d="M50.2156 87.676H56.4423C57.8483 87.676 58.9028 87.7765 59.6561 87.9773C60.6604 88.2786 61.514 88.7808 62.2171 89.534C62.9201 90.2872 63.4724 91.1911 63.824 92.2958C64.1755 93.4006 64.3763 94.7062 64.3763 96.3131C64.3763 97.7191 64.2257 98.9243 63.8742 99.9286C63.4724 101.134 62.8699 102.138 62.0664 102.891C61.4638 103.444 60.6604 103.896 59.6561 104.247C58.9028 104.498 57.8985 104.599 56.6432 104.599H50.2156V87.676ZM53.6302 90.5383V101.736H56.1912C57.1453 101.736 57.8483 101.686 58.25 101.586C58.8024 101.435 59.2543 101.234 59.6059 100.883C59.9574 100.531 60.2587 100.029 60.5097 99.2758C60.7608 98.5225 60.8612 97.468 60.8612 96.1624C60.8612 94.8568 60.7608 93.8525 60.5097 93.1495C60.2587 92.4465 59.9574 91.8941 59.5556 91.4924C59.1539 91.0907 58.6016 90.8396 57.999 90.689C57.547 90.5885 56.5929 90.5383 55.1869 90.5383H53.6302Z" fill="white"/>
      <path d="M67.2886 104.548V87.6257H79.8424V90.488H70.7032V94.2542H79.1896V97.1164H70.7032V101.736H80.1437V104.599H67.2886V104.548Z" fill="white"/>
      <path d="M83.0061 104.548V87.6257H88.1281L91.1912 99.1753L94.2041 87.6257H99.3261V104.548H96.1625V91.2412L92.7981 104.548H89.5341L86.1697 91.2412V104.548H83.0061Z" fill="white"/>
      <path d="M117.956 104.548H114.24L112.784 100.732H106.005L104.599 104.548H100.983L107.561 87.6257H111.177L117.956 104.548ZM111.679 97.8697L109.369 91.5927L107.109 97.8697H111.679Z" fill="white"/>
      <path d="M119.764 104.548V87.6257H126.945C128.752 87.6257 130.058 87.7764 130.862 88.0777C131.665 88.379 132.318 88.9313 132.82 89.6846C133.322 90.4378 133.573 91.3417 133.573 92.346C133.573 93.6014 133.222 94.6559 132.468 95.5096C131.715 96.313 130.61 96.8654 129.104 97.0662C129.857 97.5182 130.46 97.9701 130.912 98.4723C131.414 98.9744 132.016 99.8783 132.87 101.234L134.929 104.548H130.862L128.401 100.883C127.547 99.577 126.945 98.7233 126.593 98.422C126.292 98.0705 125.94 97.8697 125.589 97.719C125.237 97.5684 124.635 97.5182 123.882 97.5182H123.179V104.599H119.764V104.548ZM123.179 94.8065H125.689C127.346 94.8065 128.351 94.7563 128.752 94.6057C129.154 94.455 129.455 94.204 129.707 93.9027C129.958 93.5512 130.058 93.1494 130.058 92.6473C130.058 92.0949 129.907 91.643 129.606 91.2915C129.305 90.94 128.853 90.7391 128.301 90.6387C127.999 90.5884 127.196 90.5884 125.79 90.5884H123.128V94.8065H123.179Z" fill="white"/>
      <path d="M136.887 104.548V87.6257H140.302V95.1078L147.181 87.6257H151.751L145.374 94.204L152.102 104.548H147.684L143.013 96.6143L140.252 99.4264V104.548H136.887Z" fill="white"/>
      <path d="M160.438 104.548V87.6257H167.619C169.427 87.6257 170.732 87.7764 171.536 88.0777C172.339 88.379 172.992 88.9313 173.494 89.6846C173.996 90.4378 174.247 91.3417 174.247 92.346C174.247 93.6014 173.896 94.6559 173.143 95.5096C172.389 96.313 171.285 96.8654 169.778 97.0662C170.532 97.5182 171.134 97.9701 171.586 98.4723C172.088 98.9744 172.691 99.8783 173.544 101.234L175.603 104.548H171.536L169.075 100.883C168.222 99.577 167.619 98.7233 167.268 98.422C166.966 98.0705 166.615 97.8697 166.263 97.719C165.912 97.5684 165.309 97.5182 164.556 97.5182H163.853V104.599H160.438V104.548ZM163.853 94.8065H166.364C168.021 94.8065 169.025 94.7563 169.427 94.6057C169.829 94.455 170.13 94.204 170.381 93.9027C170.632 93.5512 170.732 93.1494 170.732 92.6473C170.732 92.0949 170.582 91.643 170.28 91.2915C169.979 90.94 169.527 90.7391 168.975 90.6387C168.674 90.5884 167.87 90.5884 166.464 90.5884H163.803V94.8065H163.853Z" fill="white"/>
      <path d="M177.511 104.548V87.6257H190.065V90.488H180.926V94.2542H189.413V97.1164H180.926V101.736H190.367V104.599H177.511V104.548Z" fill="white"/>
      <path d="M201.113 98.3719V95.5097H208.444V102.239C207.741 102.942 206.687 103.544 205.331 104.046C203.975 104.548 202.619 104.85 201.213 104.85C199.456 104.85 197.899 104.498 196.593 103.745C195.288 102.992 194.284 101.937 193.631 100.581C192.978 99.2256 192.626 97.7191 192.626 96.062C192.626 94.3045 192.978 92.7478 193.731 91.392C194.484 90.0362 195.539 88.9816 196.995 88.2284C198.1 87.676 199.406 87.3748 201.063 87.3748C203.172 87.3748 204.779 87.8267 205.984 88.6804C207.189 89.534 207.942 90.7894 208.243 92.3461L204.829 92.9989C204.578 92.1452 204.126 91.4924 203.473 91.0405C202.82 90.5885 202.017 90.3375 201.012 90.3375C199.506 90.3375 198.351 90.7894 197.447 91.7435C196.543 92.6976 196.142 94.1036 196.142 95.9616C196.142 97.9702 196.593 99.4767 197.497 100.481C198.401 101.485 199.556 101.987 201.012 101.987C201.715 101.987 202.469 101.837 203.172 101.586C203.875 101.284 204.528 100.983 205.03 100.581V98.4221H201.113V98.3719Z" fill="white"/>
      <path d="M211.507 104.548V87.6257H214.922V104.548H211.507Z" fill="white"/>
      <path d="M217.332 99.075L220.646 98.7737C220.847 99.8785 221.249 100.682 221.852 101.234C222.454 101.787 223.308 102.038 224.312 102.038C225.417 102.038 226.22 101.787 226.773 101.335C227.325 100.883 227.626 100.33 227.626 99.7278C227.626 99.3261 227.526 98.9746 227.275 98.7235C227.024 98.4222 226.622 98.2214 226.07 97.9703C225.668 97.8196 224.764 97.5686 223.358 97.2171C221.55 96.7651 220.245 96.2127 219.492 95.5599C218.437 94.6059 217.935 93.5011 217.935 92.1453C217.935 91.2916 218.186 90.4882 218.688 89.735C219.19 88.9817 219.893 88.4294 220.797 88.0276C221.701 87.6259 222.806 87.425 224.111 87.425C226.22 87.425 227.827 87.877 228.882 88.8311C229.936 89.7852 230.539 90.9903 230.589 92.547L227.174 92.6977C227.024 91.844 226.723 91.1912 226.22 90.8397C225.718 90.4882 225.015 90.2873 224.061 90.2873C223.057 90.2873 222.304 90.4882 221.751 90.8899C221.4 91.141 221.199 91.4925 221.199 91.9444C221.199 92.3462 221.35 92.6977 221.701 92.9487C222.153 93.3003 223.157 93.702 224.814 94.0535C226.471 94.4552 227.727 94.8569 228.48 95.2586C229.233 95.6604 229.886 96.263 230.338 96.966C230.79 97.669 230.991 98.5729 230.991 99.6776C230.991 100.632 230.74 101.536 230.187 102.389C229.635 103.243 228.882 103.845 227.928 104.297C226.923 104.699 225.718 104.9 224.262 104.9C222.103 104.9 220.496 104.398 219.341 103.394C218.236 102.389 217.533 100.983 217.332 99.075Z" fill="white"/>
      <path d="M237.72 104.548V90.488H232.698V87.6257H246.106V90.488H241.084V104.548H237.72Z" fill="white"/>
      <path d="M248.365 104.548V87.6257H255.546C257.354 87.6257 258.66 87.7764 259.463 88.0777C260.267 88.379 260.919 88.9313 261.421 89.6846C261.924 90.4378 262.175 91.3417 262.175 92.346C262.175 93.6014 261.823 94.6559 261.07 95.5096C260.317 96.313 259.212 96.8654 257.706 97.0662C258.459 97.5182 259.061 97.9701 259.513 98.4723C260.015 98.9744 260.618 99.8783 261.472 101.234L263.531 104.548H259.463L257.003 100.883C256.149 99.577 255.546 98.7233 255.195 98.422C254.893 98.0705 254.542 97.8697 254.19 97.719C253.839 97.5684 253.236 97.5182 252.483 97.5182H251.78V104.599H248.365V104.548ZM251.78 94.8065H254.291C255.948 94.8065 256.952 94.7563 257.354 94.6057C257.756 94.455 258.057 94.204 258.308 93.9027C258.559 93.5512 258.66 93.1494 258.66 92.6473C258.66 92.0949 258.509 91.643 258.208 91.2915C257.906 90.94 257.454 90.7391 256.902 90.6387C256.601 90.5884 255.797 90.5884 254.391 90.5884H251.73V94.8065H251.78Z" fill="white"/>
      <path d="M280.654 104.548H276.938L275.482 100.732H268.703L267.297 104.548H263.681L270.259 87.6257H273.875L280.654 104.548ZM274.377 97.8697L272.067 91.5927L269.807 97.8697H274.377Z" fill="white"/>
      <path d="M284.521 104.548V90.488H279.499V87.6257H292.907V90.488H287.885V104.548H284.521Z" fill="white"/>
      <path d="M295.016 104.548V87.6257H298.43V104.548H295.016Z" fill="white"/>
      <path d="M300.991 96.2127C300.991 94.5054 301.242 93.0492 301.744 91.8942C302.146 91.0406 302.648 90.2371 303.301 89.5843C303.954 88.8813 304.707 88.3791 305.511 88.0778C306.565 87.6259 307.77 87.425 309.176 87.425C311.687 87.425 313.645 88.1783 315.152 89.735C316.658 91.2916 317.412 93.4509 317.412 96.1625C317.412 98.8742 316.658 101.033 315.202 102.59C313.696 104.147 311.737 104.9 309.277 104.9C306.766 104.9 304.757 104.147 303.301 102.59C301.845 101.033 300.991 98.9244 300.991 96.2127ZM304.506 96.1123C304.506 98.0205 304.958 99.4767 305.812 100.481C306.716 101.485 307.821 101.988 309.176 101.988C310.532 101.988 311.637 101.485 312.541 100.531C313.394 99.527 313.846 98.0707 313.846 96.1123C313.846 94.1539 313.445 92.7479 312.591 91.7938C311.737 90.8397 310.633 90.3878 309.227 90.3878C307.82 90.3878 306.666 90.8899 305.812 91.844C304.958 92.6977 304.506 94.1539 304.506 96.1123Z" fill="white"/>
      <path d="M320.073 104.548V87.6257H323.387L330.317 98.9242V87.6257H333.48V104.548H330.066L323.237 93.5009V104.548H320.073Z" fill="white"/>
    </svg>
  )
}

export default Logo