import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import PhoneInput, {
    isValidPhoneNumber,
    parsePhoneNumber,
    getCountryCallingCode
} from "react-phone-number-input";
import { CustomModal } from "../../../components/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { withRouter, Redirect, useHistory, Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import toast from "react-hot-toast";

import AuthWrapper from "./AuthWrapper";
import { METATAGS } from "../../../constants";
import { CustomButton } from "../../../components/CustomButton";
import { CUSTOMER_ROUTES } from "../../../helpers/routeHelpers";
// import { ForgetPasswordApi } from "../api/api.service";
import loginImage from "../../../assets/images/aiAPaiT/login/login-img.png";
import Logo from "../../../assets/images/customer/svg/Logo";
import { TextInput } from "../../../components/Input";
import PhoneIcon from "../../../assets/images/customer/svg/PhoneIcon";
import { useLocation } from "react-router-dom";
import { ForgetPasswordApi } from "api/api.service";
import { SET_TOKEN, getMe } from "store/actions";
import EmailIcon from "assets/images/customer/svg/EmailIcon";

const CustomerForgetPassword = (props) => {
    let {} = props;

    const dispatch = useDispatch();
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);
    const [holdToken, setHoldToken] = useState("");
    const [showOtpModal, setShowOtpModal] = useState(false);
    const [phoneData, setPhoneData] = useState(null);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            phoneNum: ""
        },
        validationSchema: Yup.object({
            phoneNum: Yup.string()
                .required("Enter Phone Number")
                .test("phoneNum", "Phone number is invalid", function (val) {
                    return val && isValidPhoneNumber(val);
                })
        }),
        onSubmit: (values) => {
            handleSubmit(values);
        }
    });

    const handleSubmit = async (data) => {
        const { phoneNum } = data;

        let countryCodeAlpha = parsePhoneNumber(phoneNum).country;
        let countryCode = getCountryCallingCode(countryCodeAlpha);
        countryCode = `+${countryCode}`;
        let phone = phoneNum.split(countryCode)[1];

        let userPhone = {
            countryCode: countryCode,
            phone: phone
        };
        try {
            setIsLoading(true);
            await ForgetPasswordApi(userPhone)
                .then(async (res) => {
                    dispatch({ type: SET_TOKEN, token: res.token });
                    // dispatch(getMe(res.Token))
                    setHoldToken(res?.token);
                    setPhoneData(userPhone);
                    setShowOtpModal(true);

                    toast.success("OTP sent successfully !");
                })
                .catch((err) => {});
        } catch (err) {
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <MetaTags>
                <title>{METATAGS.FORGOT_PASSWORD}</title>
            </MetaTags>
            <AuthWrapper
                leftContent={
                    <div style={{ backgroundColor: "#704DE7" }} className="customer-login ">
                        <Logo />
                    </div>
                }
                data={
                    <div className="customer-login-form">
                        <div className="title">
                            <h1 className="">{"Forgot Password?"}</h1>
                            <p className="mb-4 ms-0 " style={{}}>
                                Don’t worry, Just fill your number and we'll send you an OTP to
                                reset your password via phone number and email
                            </p>
                        </div>

                        <form className="" onSubmit={formik.handleSubmit}>
                            <div className="mb-4">
                                <PhoneInput
                                    defaultCountry={"SA"}
                                    countryOptionsOrder={["SA", "AE", "OM", "BH", "KW", "QA"]}
                                    onChange={(values) => formik.setFieldValue("phoneNum", values)}
                                    onBlur={() => {
                                        formik.setFieldTouched("phoneNum");
                                    }}
                                    value={formik.values.phoneNum}
                                    name="phoneNum"
                                    placeholder="Phone Number"
                                    className="phone-num-con"
                                />
                                {formik.touched.phoneNum && formik.errors.phoneNum ? (
                                    <span className="error">{formik.errors.phoneNum}</span>
                                ) : null}
                            </div>

                            <CustomButton
                                loading={isLoading}
                                type="submit"
                                color="primary"
                                title="Get OTP"
                                className="w-100 login-btn-typo"
                            />
                        </form>
                    </div>
                }
                // leftImgUrl={loginImage}
            />
            <CustomModal
                isOpen={showOtpModal}
                data={
                    <div className="text-center">
                        <h2 className="clr-theme-primary">OTP Sent</h2>
                        <p className="my-4">
                            One Time Password has been sent to your provided <br /> phone number.
                        </p>
                        <CustomButton
                            color="primary"
                            title="Continue"
                            className="bv-btn-sm mb-3"
                            onClick={() =>
                                history.push({
                                    pathname: CUSTOMER_ROUTES.OTP_VERIFY_CODE,
                                    state: { tokenId: holdToken, phoneData: phoneData }
                                })
                            }
                        />
                    </div>
                }
            />
        </>
    );
};

export default CustomerForgetPassword;
