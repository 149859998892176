import React, { useRef } from "react";

import { CardTypes } from "../../../constants";
import Config from "Config";

function TrademarkPaymentModal(props) {
    const { paymentId, selectedCard } = { ...props };

    const iframeRef = useRef(null);
    const iframeOrigin = Config.env().REACT_APP_IFRAME_ORIGIN;
    return (
        <>
            <iframe
                src="/HyperpayScript.html"
                ref={iframeRef}
                title="Trademark Payment Modal"
                style={{ width: "100%", height: "100%", border: "none" }}
                onLoad={() => {
                    console.log("Iframe loaded");
                    iframeRef.current.contentWindow.postMessage(
                        {
                            paymentId: paymentId,
                            selectedCard: CardTypes.getDisplayTextKey(selectedCard),
                            iframeOrigin: iframeOrigin
                        },
                        iframeOrigin
                    );
                }}
            />
        </>
    );
}

export default TrademarkPaymentModal;
