import { CustomButton } from "components/CustomButton";
import React, { useEffect, useState } from "react";
import { zoneFormatToDDMMYY } from "utils/dateFormatUtils";
import "./PaymentDetailCard.scss";
import { DownloadReceiptModal } from "../../../components/DownloadReceiptModal";
import { GetAllEarningsReceipt } from "api/api.service";

const PaymentDetailCard = (props) => {
    const { data, handleOpenModal } = {
        ...props
    };

    switch (data.process) {
        case "FILING":
            data.process = "Document Submission";
            break;
        case "TMCRDOWNLOAD":
            data.process = "TMCR";
            break;
        case "POAAPPLICATION":
            data.process = "E Signature";
            break;
        case "PUBLICATION":
            data.process = "PUBLICATION";
            break;
        case "REGISTRATION":
            data.process = "REGISTRATION";
            break;
    }
    
    return (
        <>
            {" "}
            <div className="payment-detail-card p-4">
                <div className="d-flex justify-content-between align-items-center ">
                    <div className="title">Reference Number </div>
                    <div className="value">{data.referenceNumber}</div>
                </div>
                <div className="d-flex justify-content-between  align-items-center ">
                    <div className="title">Date / Time </div>
                    <div className="value">{zoneFormatToDDMMYY(data?.createdAt)}</div>
                </div>
                <div className="d-flex justify-content-between  align-items-center ">
                    <div className="title">Card Number </div>
                    <div className="value">
                        {data.meta.cardLast4Digit
                            ? `**** **** **** ${data.meta.cardLast4Digit}`
                            : "**** **** **** ****"}
                    </div>
                </div>
                <div className="d-flex justify-content-between align-items-center  ">
                    <div className="title">Payment For </div>
                    <div className="value">
                        <p>
                            Process: <span className="mx-2">{data.stepNumber}</span> {data.process}
                        </p>
                    </div>
                </div>
                <hr />
                <div className="d-flex justify-content-between align-items-center ">
                    <div className="title">Total Amount</div>
                    <div className="value">$ {data.amount}</div>
                </div>

                <CustomButton
                    title={"Download Slip"}
                    className="w-100 shadow-none"
                    color="primary"
                    onClick={handleOpenModal}
                />
            </div>
        </>
    );
};

export default PaymentDetailCard;
