import { injectBaseConstantMethods } from "./BaseConstants";

export const CASE_STATUS = {
    Open: "Open",
    Close: "Closed",
    Completed: "Completed",
    FileDownloaded: "FileDownloaded",
    Rejected: "Rejected"
};

const displayTextKeys = {
    [CASE_STATUS.Open]: "Open",
    [CASE_STATUS.Close]: "Closed",
    [CASE_STATUS.Completed]: "Completed",
    [CASE_STATUS.FileDownloaded]: "FileDownloaded",
    [CASE_STATUS.Rejected]: "Rejected"
};

const labelClass = {
    [CASE_STATUS.Open]: "green-bg-text",
    [CASE_STATUS.Close]: "red-bg-text",
    [CASE_STATUS.Completed]: "green-bg-text",
    [CASE_STATUS.FileDownloaded]: "green-bg-text"
};

export default injectBaseConstantMethods(CASE_STATUS, displayTextKeys, labelClass);
