// eslint-disable-next-line
import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
//firebase
import firebase from "firebase/app";
import "firebase/messaging";

import { CustomerProtectedRoutes, authProtectedRoutes } from "../../routes";
import { Loader } from "../../components/Loader";
import { getMe, updateFCMToken } from "../../store/actions";
import { Page500 } from "../../components/Utility";
import { APP_ROUTES, CUSTOMER_ROUTES } from "helpers/routeHelpers";
import { Socket } from "../../utils/socketUtils";
import { Notificaion } from "utils/notificationUtils";

const DefaultLayout = (props) => {
    const { Layout } = props;
    let dispatch = useDispatch();
    let { tokenVerified, isLoading } = useSelector((state) => state.root);
    const { isAuthenticated, user } = useSelector((state) => state.userAuth);
    const Role = useSelector((state) => state?.userAuth?.user?.Type);
    const userStatus = useSelector((state) => state?.userAuth?.user?.status);
    const userType = useSelector((state) => state?.userAuth?.user?.type);

    useEffect(() => {
        let notification;

        if (isAuthenticated) {
            if ("Notification" in window && firebase.messaging.isSupported()) {
                notification = new Notificaion();
                notification
                    .getToken()
                    .then((token) => {
                        user?.fcmToken !== token && dispatch(updateFCMToken(token)).catch(() => {});
                    })
                    .catch((err) => {});
            }
            if (!tokenVerified) {
                dispatch(getMe()).catch(() => {});
            }
        }
        return () => {
            if (notification) {
                notification.removeListeners();
            }
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (isAuthenticated) {
            Socket.init();
            if (!tokenVerified) {
                dispatch(getMe()).catch(() => {});
            }
        }
    }, []);

    if (!isAuthenticated) {
        return <Redirect to={CUSTOMER_ROUTES.LOGIN} />;
    }

    let roleBasedRoutes;

    if (userType == "USER") {
        roleBasedRoutes = CustomerProtectedRoutes;
    }

    if (userType == "ADMIN" || userType == "SUBADMIN") {
        roleBasedRoutes = authProtectedRoutes;
    }

    // const roleBasedRoutes = authProtectedRoutes;
    // roleBasedRoutes = CustomerProtectedRoutes;

    return tokenVerified ? (
        <Switch>
            {roleBasedRoutes.map((route, index) => {
                return (
                    <Route
                        key={index}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={(props) => (
                            <Layout>
                                <route.component {...props} />
                            </Layout>
                        )}
                    />
                );
            })}

            {userType === "ADMIN" || userType === "SUBADMIN" ? (
                <Redirect to={APP_ROUTES.DASHBOARD} />
            ) : null}
            {userType === "USER" ? <Redirect to={CUSTOMER_ROUTES.TRADEMARK} /> : null}
        </Switch>
    ) : !tokenVerified && isLoading ? (
        <Loader classes="vh-100" showVerifyText={true} />
    ) : // <Page500 />
    null;
};

export default DefaultLayout;

DefaultLayout.propTypes = {
    Layout: PropTypes.any
};
