import React from "react";

const CrossIcon = () => {
    return (
        <svg
            width="118"
            height="118"
            viewBox="0 0 118 118"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M80.3603 73.9486L65.4117 59.0001L80.3603 44.0515C82.1189 42.2929 82.1189 39.3996 80.3603 37.6409C78.6016 35.8823 75.7083 35.8823 73.9497 37.6409L59.0011 52.5895L44.0526 37.6409C42.2939 35.8823 39.6276 35.627 37.642 37.6409C35.628 39.6549 35.9401 42.3496 37.642 44.0515L52.5905 59.0001L37.642 73.9486C35.7415 75.8491 35.8266 78.5722 37.642 80.3592C39.4574 82.1746 42.2939 82.1179 44.0526 80.3592L59.0011 65.4107L73.9497 80.3592C75.7083 82.1179 78.6016 82.1179 80.3603 80.3592C82.1473 78.6006 82.1473 75.7073 80.3603 73.9486Z"
                fill="#F34D4D"
            />
            <path
                d="M59 7.94231C72.6438 7.94231 85.4649 13.2466 95.1091 22.8909C104.753 32.5351 110.058 45.3563 110.058 59C110.058 72.6438 104.753 85.4649 95.1091 95.1091C85.4649 104.753 72.6438 110.058 59 110.058C45.3563 110.058 32.5351 104.753 22.8909 95.1091C13.2466 85.4649 7.94231 72.6438 7.94231 59C7.94231 45.3563 13.2466 32.5351 22.8909 22.8909C32.5351 13.2466 45.3563 7.94231 59 7.94231ZM59 0C26.4082 0 0 26.4082 0 59C0 91.5918 26.4082 118 59 118C91.5918 118 118 91.5918 118 59C118 26.4082 91.5918 0 59 0Z"
                fill="#F34D4D"
            />
        </svg>
    );
};

export default CrossIcon;
