import firebase from "firebase/app";
import "firebase/messaging";

import { store } from "../store";
import { updateNotificationCount, UPDATE_NOTIFICATION_COUNT } from "store/actions";

const PostMessageTypes = {
    SHOW_NOTIFICATION: "ShowNotification"
};

const FIREBASE_CONFIG = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

export class Notificaion {
    messaging;
    static FirebaseMessaging;
    notificationListener;
    constructor() {
        this.init();
    }

    init() {
        if (!firebase.apps.length) {
            firebase.initializeApp(FIREBASE_CONFIG);
        }
        this.messaging = firebase.messaging();
        Notificaion.FirebaseMessaging = this.messaging;

        this.notificationListener = this.messaging.onMessage(this.handleMessageRecieved.bind(this));
        if ("serviceWorker" in navigator) {
            navigator.serviceWorker.addEventListener(
                "message",
                this.listenMessageFromServiceWorker
            );
        }
    }

    removeListeners() {
        if (this.notificationListener) {
            // removes this onMessage firebase listner
            this.notificationListener();
        }
        if ("serviceWorker" in navigator) {
            navigator.serviceWorker.removeEventListener(
                "message",
                this.listenMessageFromServiceWorker
            );
        }
    }

    // listens in app notifications
    handleMessageRecieved(payload) {
        const { data } = payload;
        // const currentChatId = store.getState().chat.currentChat.id;

        // if (
        //     !data ||
        //     (parseInt(data.Type) === NotificationTypes.NewChatMessage &&
        //         parseInt(data.EntityType) === EntityTypes.Chat &&
        //         currentChatId &&
        //         currentChatId === parseInt(data.EntityId))
        // ) {
        //     return;
        // }

        this.sendMessageToServiceWorker({
            Type: PostMessageTypes.SHOW_NOTIFICATION,
            Payload: payload
        });
    }

    listenMessageFromServiceWorker = (event) => {
        const { Type, Payload } = event.data;

        switch (Type) {
            case PostMessageTypes.SHOW_NOTIFICATION:
                this.showNotification(Payload);
                break;
            default:
                break;
        }
    };

    showNotification(data) {
        if (
            "serviceWorker" in navigator &&
            window.Notification &&
            window.Notification.permission === "granted" &&
            data &&
            data.notification
        ) {
            navigator.serviceWorker.ready.then((reg) => {
                reg.showNotification(data.notification.title, {
                    icon: "https://homepage-stg.s3.us-east-2.amazonaws.com/static/logo+clr+x1370.png",
                    body: data.notification.body,
                    data: { FCM_MSG: data }
                });
                // add dispatch here to update notification

                store.dispatch(updateNotificationCount());
            });
        }
    }

    sendMessageToServiceWorker = ({ Type, Payload }) => {
        if ("serviceWorker" in navigator) {
            navigator.serviceWorker.ready.then((reg) => {
                reg.active.postMessage({ Type, Payload });
            });
        }
    };

    async requestPermission() {
        return await this.messaging.requestPermission();
    }

    static async deleteToken() {
        return await Notificaion.FirebaseMessaging.deleteToken();
    }

    async getToken() {
        try {
            await this.requestPermission();
            return await this.messaging.getToken();
        } catch (err) {
            console.log(err);
            throw err;
        }
    }
}
