import React, { useEffect, useState } from "react";
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import "./PaymentTabs.scss";
import AddPaymentModal from "./AddPaymentModal";
import { CustomButton } from "components/CustomButton";
import { getAllEarningForCustomer } from "api/api.service";
import PaymentCard from "./PaymentCard";
import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest";
import { Loader } from "components/Loader";
import { Pagination } from "../../../components/Pagination";

const PaymentTabs = () => {
    const [ismodalOpen, setismodalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    // const [page, setpage] = useState(1);
    const [paymentHistory, setPaymentHistory] = useState([]);

    // const getPaymentHistory = async () => {
    //     try {
    //         setIsLoading(true);
    //         let res = await getAllEarningForCustomer({ page: page });
    //         console.log(res, "res");
    //         let paymentHistoryMerged = page === 1 ? res?.data : [...paymentHistory, ...res?.data];
    //         setPaymentHistory(paymentHistoryMerged);
    //     } catch (err) {
    //     } finally {
    //         setIsLoading(false);
    //     }
    // };
    const {
        page,
        handlePageClick,
        pageSize,
        onChangePageSize,
        searchText,
        handleSearchChange,
        updatePageOnFilterChange,
        data,
        total,
        isFetching
    } = useLocalPaginatedRequest({
        requestFn: getAllEarningForCustomer
    });

    // useEffect(() => {
    //     getPaymentHistory();
    // }, [page]);

    console.log(data, "data");

    return (
        <React.Fragment>
            <Row className="py-4 px-2">
                {data.length > 0 && !isFetching ? (
                    data.map((item, idx) => {
                        return (
                            <Col md={6} key={idx}>
                                <PaymentCard data={item} />
                            </Col>
                        );
                    })
                ) : data.length === 0 && !isFetching ? (
                    <div className=" my-5 d-flex justify-content-center align-items-center">
                        <h3 className="text-center">No Item Found at the moment</h3>
                    </div>
                ) : (
                    <Loader classes="vh-50" showVerifyText={false} />
                )}
            </Row>
            {data.length > 0 && !isFetching ? (
                <div className="d-flex justify-content-end">
                    <Pagination
                        currentPage={page}
                        totalData={total}
                        onPageClick={handlePageClick}
                        className="pagination-contain "
                        rowLimit={pageSize}
                    />
                </div>
            ) : null}
        </React.Fragment>
    );
};

export default PaymentTabs;
