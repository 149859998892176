import PropTypes from "prop-types";
import React from "react";
import Mobilelogo from "../../../assets/images/aiAPaiT/logo-primary-better.svg";
import { Container } from "reactstrap";

import "../AuthWrapper.scss";
// import "../AuthWrapper-responsive.scss"

const CustomerAuthWrapper = (props) => {
    const { className = "", leftContent, data } = props;
    return (
        <div className={`cs-auth-wrapper ${className} d-flex align-items-stretch min-vh-100`}>
            <div className=" col-md-6 align-items-center auth-left-img-con">
                {/* <Container className="h-100"> */}
                <div className="d-flex flex-column justify-content-evenly w-100 h-100">
                    {leftContent}
                </div>
                {/* </Container> */}
            </div>
            <div className="d-flex col-md-6 align-items-center ">
                <Container>
                    <div className="offset-xxl-2 col-xxl-8 offset-md-2 col-md-8">
                        <div className="py-4 d-flex justify-content-center mobile-logo ">
                            <img src={Mobilelogo} alt="companyLogo" />
                        </div>
                        {data}
                    </div>
                </Container>
            </div>
        </div>
    );
};

CustomerAuthWrapper.propTypes = {
    className: PropTypes.any,
    leftContent: PropTypes.any,
    leftImgUrl: PropTypes.any,
    data: PropTypes.any
};

export default CustomerAuthWrapper;
