import React from "react";
import "./AlertModal.scss";
import { CustomModal } from "components/CustomModal";
import { CustomButton } from "components/CustomButton";

const AlertModal = (props) => {
    const { isOpen, title, icon, content, btn_content, isClose } = { ...props };
    return (
        <CustomModal
            isOpen={isOpen}
            data={
                <div className="alert-modal ">
                    <div>
                        <h2>{title}</h2>
                    </div>
                    <div className="alert-image">{icon}</div>
                    <div>
                        <p>{content}</p>
                    </div>

                    <CustomButton
                        color="primary"
                        title={btn_content}
                        className="bv-btn-sm mt-3"
                        onClick={isClose}
                    />
                </div>
            }
        />
    );
};

export default AlertModal;
