import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

import { CUSTOMER_ROUTES } from "../../helpers/routeHelpers";

//icons
import TrademarkIcon from "assets/images/customer/svg/TrademarkIcon";
import ProfileIcon from "assets/images/customer/svg/ProfileIcon";
import ChatIcon from "assets/images/customer/svg/ChatIcon";
import SettingIcon from "assets/images/customer/svg/SettingIcon";

import "./SidebarContent.scss";
import PaymentIcon from "assets/images/customer/svg/PaymentIcon";

const SidebarContent = (props) => {
    const userObj = useSelector((store) => store?.userAuth?.user?.type);
    const ref = useRef();
    // Use ComponentDidMount and ComponentDidUpdate method symultaniously
    useEffect(() => {
        const pathName = props.location.pathname;

        const initMenu = () => {
            new MetisMenu("#side-menu");
            let matchingMenuItem = null;
            const ul = document.getElementById("side-menu");
            const items = ul.getElementsByTagName("a");
            for (let i = 0; i < items.length; ++i) {
                // if (pathName === items[i].pathname) {
                if (pathName.includes(items[i].pathname)) {
                    matchingMenuItem = items[i];
                    break;
                }
                // }
            }
            if (matchingMenuItem) {
                activateParentDropdown(matchingMenuItem);
            }
        };
        initMenu();
    }, [props.location.pathname]);

    useEffect(() => {
        ref.current.recalculate();
    });

    function scrollElement(item) {
        if (item) {
            const currentPosition = item.offsetTop;
            if (currentPosition > window.innerHeight) {
                ref.current.getScrollElement().scrollTop = currentPosition - 300;
            }
        }
    }

    function activateParentDropdown(item) {
        item.classList.add("active");
        const parent = item.parentElement;
        const parent2El = parent.childNodes[1];
        if (parent2El && parent2El.id !== "side-menu") {
            parent2El.classList.add("mm-show");
        }

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show"); // ul tag

                const parent3 = parent2.parentElement; // li tag

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement; // ul
                    if (parent4) {
                        parent4.classList.add("mm-show"); // ul
                        const parent5 = parent4.parentElement;
                        if (parent5) {
                            parent5.classList.add("mm-show"); // li
                            parent5.childNodes[0].classList.add("mm-active"); // a tag
                        }
                    }
                }
            }
            scrollElement(item);
            return false;
        }
        scrollElement(item);
        return false;
    }

    return (
        <React.Fragment>
            <SimpleBar className="h-100" ref={ref}>
                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled sidebar-menu" id="side-menu">
                        <li>
                            <Link to={CUSTOMER_ROUTES.TRADEMARK}>
                                <TrademarkIcon />
                                <span>Trademarks</span>
                            </Link>
                        </li>
                        <li>
                            <Link to={CUSTOMER_ROUTES.PROFILE}>
                                <ProfileIcon />
                                <span>My Profile</span>
                            </Link>
                        </li>

                        <li>
                            <Link to={CUSTOMER_ROUTES.CHATS}>
                                <ChatIcon />
                                <span>Chat And Call Support</span>
                            </Link>
                        </li>
                        <li>
                            <Link to={CUSTOMER_ROUTES.PAYMENT}>
                                <PaymentIcon />
                                <span>Payments</span>
                            </Link>
                        </li>
                        <li>
                            <Link to={CUSTOMER_ROUTES.SETTINGS} className="">
                                <SettingIcon />
                                <span>Settings</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </SimpleBar>
        </React.Fragment>
    );
};

SidebarContent.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any
};

export default withRouter(withTranslation()(SidebarContent));
