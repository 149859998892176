import React, { useState } from "react";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { Col, Form, FormGroup, Label } from "reactstrap";
import { useFormik } from "formik";

import { TextInput } from "components/Input";
import { CustomButton } from "components/CustomButton";
import { ConfirmationModal } from "../common/ConfirmationModal";
import { deleteUser } from "api/api.service";
import { store } from "store";
import { SIGN_OUT } from "store/actions";

const DeleteUser = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isUserDelete, setIsUserDelete] = useState(false);

    const userData = useSelector((store) => store?.userAuth?.user);

    const formik = useFormik({
        initialValues: {
            description: ""
        },
        validationSchema: Yup.object({
            description: Yup.string()
                .required("Enter DELETE")
                .oneOf(["DELETE"], "You must type DELETE to confirm")
        }),
        onSubmit: (values) => {
            if (values.description == "DELETE") {
                setIsUserDelete(true);
            }
        }
    });

    const deleteHandler = async () => {
        setIsLoading(true);
        try {
            const res = await deleteUser(userData?.id);
            if (res?.message == "Deleted") {
                store.dispatch({ type: SIGN_OUT });
                localStorage.clear();
            }
        } catch (err) {
            console.error("Failed to delete user", err);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => setIsUserDelete(false);

    return (
        <div>
            <React.Fragment>
                <Form onSubmit={formik.handleSubmit} className="profile-setting-form">
                    <FormGroup row>
                        <Col sm={12}>
                            <Label for="description">Type DELETE to confirm</Label>
                        </Col>
                        <Col sm={12}>
                            <TextInput
                                placeholder="DELETE"
                                name={"description"}
                                type="text"
                                className="hide-icon"
                                inputGroupClassName={`${
                                    formik.touched.description &&
                                    formik.errors.description &&
                                    "border-danger"
                                }`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.description}
                                autoFocus
                            />
                            {formik.touched.description && formik.errors.description ? (
                                <span className="error">{formik.errors.description}</span>
                            ) : null}
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Col className="text-end">
                            <CustomButton
                                type="submit"
                                color="danger"
                                title="Delete"
                                className=" mb-5 w-50 btn-outline-danger delete-btn"
                            />
                        </Col>
                    </FormGroup>
                </Form>
            </React.Fragment>
            <ConfirmationModal
                isOpen={isUserDelete}
                content={"Are you sure you want to delete your account?"}
                btn_content={"Yes"}
                onYes={deleteHandler}
                onNo={handleCancel}
                onYesLoading={isLoading}
            />
        </div>
    );
};

export default DeleteUser;
