import React, { useState } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { withRouter, useHistory, Redirect, useLocation } from "react-router-dom";

import AuthWrapper from "./AuthWrapper";
import { CustomModal } from "components/CustomModal";
import { CodeInput } from "components/CodeInput";
import { CustomButton } from "components/CustomButton";
import { METATAGS } from "../../constants";
import { APP_ROUTES, getRoute } from "helpers/routeHelpers";
import { ForgetPasswordVerification, ResendOtp } from "api/api.service";
import loginImage from "assets/images/aiAPaiT/login/login-img.png";

const OTP_LENGTH = 4;
const ForgetVerifyCode = (props) => {
    let {} = props;

    const history = useHistory();
    const { state } = useLocation();

    let isAuthenticated = useSelector((state) => state?.userAuth?.isAuthenticated);

    const [isLoading, setIsLoading] = useState(false);
    const [OTP, setOTP] = useState("");
    const [codeAndToken, setCodeAndToken] = useState({});
    const [isResendCode, setIsResendCode] = useState(false);
    const [showOtpModal, setShowOtpModal] = useState(false);
    const [resetPassword, setResetPassword] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            let body = {
                token: state?.tokenId,
                code: OTP
            };
            let res = await ForgetPasswordVerification(body);
            toast.success("OTP has been verified successfully!");
            setTimeout(() => {
                setResetPassword(true);
            }, 1200);
            let { token } = res;
            setCodeAndToken({ tokenUId: token });
        } finally {
            setTimeout(() => {
                setIsLoading(false);
            }, 1200);
        }
    };

    const handleResendCode = async () => {
        try {
            setIsResendCode(true);
            let body = {
                token: state?.tokenId,
                countryCode: state?.phoneData?.countryCode,
                phone: state?.phoneData?.phone
            };
            let res = await ResendOtp(body);
            toast.success("An OTP has been sent to your phone number and email !");
            history.push({
                pathname: APP_ROUTES.OTP_VERIFY_CODE,
                state: { tokenId: res?.token }
            });
        } finally {
            setIsResendCode(false);
            setOTP("");
        }
    };

    if (resetPassword) {
        history.push({
            pathname: APP_ROUTES?.RESET_PASSWORD,
            state: { tokenId: codeAndToken?.tokenUId }
        });
    }

    if (!location?.state?.email) {
        <Redirect to={APP_ROUTES.DASHBOARD} />;
    }

    if (isAuthenticated) {
        return <Redirect to={APP_ROUTES.DASHBOARD} />;
    }

    return (
        <>
            <MetaTags>
                <title>{METATAGS.FORGOT_VERIFY_CODE}</title>
            </MetaTags>
            <AuthWrapper
                leftContent={
                    <div className="text-center">
                        <h1 className="text-white fw-normal">
                            <span>ONE TIME PASSWORD</span>
                            <span className="d-block font-size-14">
                                Don't share this code with anyone else.
                            </span>
                        </h1>
                    </div>
                }
                data={
                    <div className="bv-forget-wrap margin-top-vh-vc">
                        <p className="login-type ms-0">One Time Password</p>
                        <form className="mt-3">
                            <CodeInput className="my-4 text-left" onChange={(e) => setOTP(e)} />
                            <CustomButton
                                disabled={OTP.length != OTP_LENGTH}
                                loading={isLoading}
                                onClick={handleSubmit}
                                color="primary"
                                type={"submit"}
                                title="Verify"
                                className="w-100 mt-5 ms-0"
                            />
                        </form>
                        <div
                            className="d-flex mt-4 align-items-center justify-content-center"
                            onClick={handleResendCode}
                        >
                            <a className="resend-otp-typo">
                                {isResendCode ? "Sending..." : "Resend OTP"}
                            </a>
                        </div>
                    </div>
                }
                leftImgUrl={loginImage}
            />
            <CustomModal
                isOpen={showOtpModal}
                data={
                    <div className="text-center">
                        <CustomButton
                            color="primary"
                            title="Continue"
                            className="bv-btn-sm"
                            onClick={() => {
                                history.push(
                                    getRoute(APP_ROUTES.RESET_PASSWORD, {
                                        tokenId: codeAndToken?.tokenUId
                                    })
                                );
                            }}
                        />
                    </div>
                }
            />
        </>
    );
};

ForgetVerifyCode.propTypes = {
    history: PropTypes.object
};

export default withRouter(ForgetVerifyCode);
