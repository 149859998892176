import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import UserAvatar from "../../assets/images/aiAPaiT/chat/avatar-icon.svg";
import backIcon from "../../assets/images/aiAPaiT/back-arrow.svg";
import AdminChatIcon from "../../assets/images/aiAPaiT/chat/admin-chat-icon.svg";
import { escapeHTMLElementsFromMessage } from "../../utils/commonUtils";
import { Socket, SocketTypes } from "../../utils/socketUtils";
import Messages from "./messages";
import SendMessage from "./send-message";
import { scrollToBottom } from "../../utils/domUtils";
import { getChatById } from "api/api.service";
import { APP_ROUTES } from "helpers/routeHelpers";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Details = ({
    userData,
    isSidebarToggleActive,
    setIsSidebarToggleActive,
    onMessageReceived
}) => {
    const history = useHistory();
    const [data, setData] = useState([]);
    const [groupedData, setGroupedData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(null);


    useEffect(async () => {
        setLoading(true);
        Socket.joinChat(userData?.id);
        try{
            let res = await getChatById(userData?.id, {
                column: "updatedAt",
                direction: "DESC",
                page: 1
            });
            setTotal(res.total);
            let reverse = [...res.messages].reverse();
            setData(reverse);
            setPage(1);
            let lastMessageId = reverse[reverse?.length - 1]?.id;
            let chatId = reverse[reverse?.length - 1]?.chatId;
            Socket.readMessage(lastMessageId, chatId, (data) => {});
            scrollToBottom();
        }catch(err){
            history.push( APP_ROUTES.CHATS);
        }finally{
            setLoading(false);
        }  
        
        return () => {
            setData([]);
        };
    }, [userData?.id]);

    const toggleSidebar = () => {
        setIsSidebarToggleActive(!isSidebarToggleActive);
    };

    const handleMessageSent = ({ messageContent, media }) => {
        const hasMedia = media && media?.id;
        const escapedMessage = escapeHTMLElementsFromMessage(messageContent);
        const socketMsgData = {
            chatId: userData?.id,
            ...(messageContent?.length && {content: escapedMessage}) ,
            ...(hasMedia && {   mediaIds: [media?.id] })
        };
        Socket?.sendMessage(socketMsgData, async (newMsg) => {
            let updated = [...data, newMsg?.chatEvent];
            setData(updated);
        });
        onMessageReceived({ message: messageContent });
    };

    useEffect(() => {
        let responses = [];
        if (data?.length) {
            const groups = data?.reduce((groups, item) => {
                const date = item?.updatedAt.split("T")[0];

                if (!groups[date]) {
                    groups[date] = [];
                }
                groups[date].push(item);
                return groups;
            }, {});
            responses = Object.keys(groups).map((date) => {
                return {
                    date,
                    chat: groups[date]
                };
            });
        }
        setGroupedData(responses);
        setTimeout(() => {
            scrollToBottom();
        }, 0);
    }, [data]);

    const handleScroll = async (e) => {
        const isTop = e.target.scrollTop < 30;
        if (loading) return;
        if (data?.length >= total) return;
        if (isTop && data) {
            let container = document.getElementById("chatbox-scroll-div");
            let prevScrollTop = container.scrollTop;
            let prevScrollHeight = container.scrollHeight;
            try {
                setLoading(true);
                let res = await getChatById(userData?.id, {
                    column: "updatedAt",
                    direction: "DESC",
                    page: page + 1
                });
                let reverse = [...res.messages].reverse();
                setData([...reverse, ...data]);
                setPage(page + 1);
                container.scrollTop = container.scrollHeight - (prevScrollHeight - prevScrollTop);
            } catch (err) {
            } finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        if (data?.length) {
            async function handleMessageRecieved(newMsg) {
                if (data[0]?.chatId == newMsg?.chatEvent?.chatId) {
                    let updated = [...data, newMsg?.chatEvent];
                    setData(updated);
                }
            }
            Socket.onMessageRecieved(handleMessageRecieved);
            return () => {
                Socket.remove(SocketTypes.MESSAGE, handleMessageRecieved);
            };
        }
    }, [data]);


    return (
        <>
        {
            Object.keys(userData)?.length > 1
              ? (
                <div className="bg-white py-4 radius shadow conversation-height conversation-chat-container right-chat-panel-con p-3">
            <img
                onClick={toggleSidebar}
                src={backIcon}
                alt="close modal icon"
                className="chat-panel-arrow-icon"
            />

            <div className="d-flex justify-content-between align-items-center">
                {userData?.participants?.map((user, ind) => {
                    if (user?.memberType === "USER") {
                        return (
                                <div className="d-flex justify-start align-items-center ma-0 py-3 mb-3 recipients " key={ind}>
                                    <img
                                        className="me-3 top-header-left-img"
                                        src={
                                            user?.user?.profilePicture
                                                ? user?.user?.profilePicture
                                                : UserAvatar
                                        }
                                    />
                                    <div className="d-flex flex-column flex-fill ml-3 justify-center recipients-row">
                                        <span className="recipients-name">
                                            {user?.user?.name ? user?.user?.name : ""}
                                        </span>
                                        <span className="d-flex mt-1">
                                            <span className="recipients-question d-inline-block justify-center align-center ml-4 text-truncate">
                                                Customer
                                            </span>
                                        </span>
                                    </div>
                                </div>
                        );
                    }
                })}

                {userData?.participants?.map((user, ind) => {
                    if (user?.memberType === "ADMIN" || user?.memberType === "SUBADMIN") {
                        return (
                                <div className="d-flex justify-start align-items-center ma-0 py-3 mb-3 recipients " key={ind}>
                                    <div className="d-flex flex-column flex-fill mx-3 justify-center recipients-row">
                                        <span className="recipients-name">{user?.user?.name}</span>
                                        <span className="d-flex mt-1">
                                            <span className="recipients-question d-inline-block justify-center align-center ml-4 text-truncate">
                                                Department
                                            </span>
                                        </span>
                                    </div>
                                    <img src={AdminChatIcon} className="top-header-left-img" />
                                </div>
                        );
                    }
                })}
            </div>
            <div className="recipient-details">
                <div className="chat">
                    <div className="conversation">
                        { groupedData.length ? (<div
                            className="messages fancy-scroll chat-scroll-update conversation-message-con px-0"
                            onScroll={handleScroll}
                            id="chatbox-scroll-div"
                        >
                            <Messages userData={userData} list={groupedData} loading={loading} />
                        </div>):
                        (<div  className="messages overflow-hidden cpx-0 d-flex align-items-center justify-content-center ">
                            <p className="text-center recipients-name ">Chat not found! Please type to chat.</p>
                        </div>)}

                        <SendMessage onMessageSent={handleMessageSent} />
                    </div>
                </div>
            </div>
        </div>
            ) : ""
        }
        
        </>
    );
};

// handling prop types
Details.propTypes = {
    socket: PropTypes.any,
    userData: PropTypes.any,
    isSidebarToggleActive: PropTypes.any,
    setIsSidebarToggleActive: PropTypes.any
};

export default Details;
