import { GetAllEarningsReceipt, GetEarningById } from "api/api.service";
import PaymentDetailCard from "components/customer/Payment/PaymentDetailCard";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Col, Row } from "reactstrap";
import { Loader } from "components/Loader";
import ReceiptModal from "components/customer/Payment/ReceiptModal";
import { CustomButton } from "components/CustomButton";
const PaymentDetail = () => {
    const params = useParams();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [receiptData, setReceiptData] = useState(undefined);
    const [isDownloadReceiptModalActive, setIsDownloadReceiptModalActive] = useState(false);
    const [isDataFetching, setIsDataFetching] = useState(false);

    useEffect(async () => {
        setLoading(true);
        const res = await GetEarningById(Number(params?.id));
        setData(res);
        setLoading(false);
    }, [params?.id]);

    const ButtonClickHanlder = (data) => {
        setIsDataFetching(true);
        setReceiptData(data);
        setIsDownloadReceiptModalActive(true);

        setIsDataFetching(false);
    };
    const downloadReceiptModalCloseHandler = () => {
        setIsDownloadReceiptModalActive(false);
    };
    return (
        <>
            {" "}
            <div className="payment-detail p-4">
                <Row className="payment_customer_detail p-4">
                    <Col>
                        <h6>Customer Name</h6>
                        <p>{data.customerName}</p>
                    </Col>
                    <Col>
                        <h6>Customer’s Phone Number</h6>
                        <p>{data.customerPhone}</p>
                    </Col>
                    <Col>
                        <h6>Case ID</h6>
                        <p>{data.caseId}</p>
                    </Col>
                    <Col>
                        <h6>Case Title</h6>
                        <p>{data.caseTitle}</p>
                    </Col>
                    <Col>
                        <h6>Total Spending</h6>
                        <p>${data.totalSpendings}</p>
                    </Col>
                    <Col>
                        <CustomButton
                            title={"Download Receipt"}
                            className="w-100 shadow-none"
                            color="primary"
                            onClick={() => ButtonClickHanlder(data.payments)}
                        />
                    </Col>
                </Row>

                <Row className="py-4">
                    {data.payments &&
                        data.payments.map((items) => {
                            return (
                                <Col md={3}>
                                    <PaymentDetailCard
                                        data={items}
                                        handleOpenModal={() => ButtonClickHanlder([items])}
                                    />
                                </Col>
                            );
                        })}
                </Row>
            </div>
            {isDownloadReceiptModalActive ? (
                <ReceiptModal
                    modalActivator={isDownloadReceiptModalActive}
                    handleClose={downloadReceiptModalCloseHandler}
                    modalData={receiptData}
                    caseRelatedData={data}
                />
            ) : (
                ""
            )}
        </>
    );
};

export default PaymentDetail;
