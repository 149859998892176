import React, { useState } from "react";
import PasswordLockIcon from "assets/images/customer/svg/PasswordLockIcon";
import { TextInput } from "components/Input";
import * as Yup from "yup";
import { Col, Container, Form, FormGroup, Label, Row } from "reactstrap";
import PhoneInput, {
    isValidPhoneNumber,
    parsePhoneNumber,
    getCountryCallingCode
} from "react-phone-number-input";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import passIcon from "assets/images/aiAPaiT/lock-icon.png";
import showPassIcon from "assets/images/aiAPaiT/show-lock-icon.svg";

const Profile = () => {
    let userData = useSelector((store) => store?.userAuth?.user);

    const data = {
        full_name: userData.name,
        email: userData.email,
        phoneNum: `${userData?.countryCode}${userData?.phone}`
    };

    return (
        <React.Fragment>
            <div className="p-3 profile">
                {/* <MetaTags><title>{METATAGS.TRADEMARKS}</title></MetaTags> */}
                <Container fluid>
                    <Row className="greetings">
                        <h1>{data.full_name}</h1>
                        <hr />
                    </Row>
                </Container>
                <Container fluid>
                    <Form className="profile-setting-form">
                        <FormGroup row className="d-flex align-items-center">
                            <Col sm={12} md={2}>
                                <Label for="exampleEmail">Full Name</Label>
                            </Col>

                            <Col sm={12} md={10}>
                                <TextInput
                                    placeholder="John Smith"
                                    name={"full_name"}
                                    type="text"
                                    className="hide-icon"
                                    value={data.full_name}
                                    disabled
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row className="d-flex align-items-center">
                            <Col sm={12} md={2}>
                                <Label for="exampleEmail">Email</Label>
                            </Col>

                            <Col sm={12} md={10}>
                                <TextInput
                                    placeholder="John Smith"
                                    name={"email"}
                                    type="email"
                                    className="hide-icon"
                                    value={data.email}
                                    disabled
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row className="d-flex align-items-center">
                            <Col sm={12} md={2}>
                                <Label for="exampleEmail">Phone</Label>
                            </Col>

                            <Col sm={12} md={10}>
                                <TextInput
                                    placeholder="John Smith"
                                    name={"phoneNum"}
                                    type="tel"
                                    className="hide-icon"
                                    value={data.phoneNum}
                                    disabled
                                />
                            </Col>
                        </FormGroup>
                    </Form>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Profile;
