import { injectBaseConstantMethods } from "./BaseConstants";

export const DOCUSIGN_EVENT_QUERY_PARAMS = {
    AccessCodeFailed: "access_code_failed",
    Cancel: "cancel",
    Decline: "decline",
    Exception: "exception",
    FaxPending: "fax_pending",
    IdCheckFailed: "id_check_failed",
    SessionTimeout: "session_timeout",
    SigningComplete: "signing_complete",
    TtlExpired: "ttl_expired",
    ViewingComplete: "viewing_complete"
};

const displayTextKeys = {
    [DOCUSIGN_EVENT_QUERY_PARAMS.AccessCodeFailed]: "Access Code Failed",
    [DOCUSIGN_EVENT_QUERY_PARAMS.Cancel]: "Canceled",
    [DOCUSIGN_EVENT_QUERY_PARAMS.Decline]: "Declined",
    [DOCUSIGN_EVENT_QUERY_PARAMS.Exception]: "Exception Occurred",
    [DOCUSIGN_EVENT_QUERY_PARAMS.FaxPending]: "Fax Pending",
    [DOCUSIGN_EVENT_QUERY_PARAMS.IdCheckFailed]: "ID Check Failed",
    [DOCUSIGN_EVENT_QUERY_PARAMS.SessionTimeout]: "Session Timeout",
    [DOCUSIGN_EVENT_QUERY_PARAMS.SigningComplete]: "Signing Complete",
    [DOCUSIGN_EVENT_QUERY_PARAMS.TtlExpired]: "TTL Expired",
    [DOCUSIGN_EVENT_QUERY_PARAMS.ViewingComplete]: "Viewing Complete"
};

const labelClass = {
    [DOCUSIGN_EVENT_QUERY_PARAMS.AccessCodeFailed]: "",
    [DOCUSIGN_EVENT_QUERY_PARAMS.Cancel]: "",
    [DOCUSIGN_EVENT_QUERY_PARAMS.Decline]: "",
    [DOCUSIGN_EVENT_QUERY_PARAMS.Exception]: "",
    [DOCUSIGN_EVENT_QUERY_PARAMS.FaxPending]: "",
    [DOCUSIGN_EVENT_QUERY_PARAMS.IdCheckFailed]: "",
    [DOCUSIGN_EVENT_QUERY_PARAMS.SessionTimeout]: "",
    [DOCUSIGN_EVENT_QUERY_PARAMS.SigningComplete]: "",
    [DOCUSIGN_EVENT_QUERY_PARAMS.TtlExpired]: "",
    [DOCUSIGN_EVENT_QUERY_PARAMS.ViewingComplete]: ""
};

export default injectBaseConstantMethods(DOCUSIGN_EVENT_QUERY_PARAMS, displayTextKeys, labelClass);
