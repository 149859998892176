import React, { useState } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { Redirect } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import AuthWrapper from "./AuthWrapper";
import { METATAGS } from "../../constants";
import { TextInput } from "components/Input";
import { CustomButton } from "components/CustomButton";
import { APP_ROUTES } from "helpers/routeHelpers";
import { login } from "store/actions";
import toast from "react-hot-toast";
import loginImage from "assets/images/aiAPaiT/login/login-img.png";
import passIcon from "assets/images/aiAPaiT/lock-icon.png";
import showPassIcon from "assets/images/aiAPaiT/show-lock-icon.svg";

const Login = (props) => {
    let { } = props;
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [passwordFieldType, setPasswordFieldType] = useState(false);
    let isAuthenticated = useSelector((state) => state.userAuth.isAuthenticated);

    const passwordFieldChangeHandler = () => {
        setPasswordFieldType(!passwordFieldType)
    }

    const formik = useFormik({
        initialValues: {
            email: "",
            password: ""
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email("Enter a valid email address")
                .required("Enter email address")
                .min(6, "Too short email!")
                .max(50, "Too long email!")
                .matches(
                    /([A-Z|a-z|0-9](\.|_){0,1})+[A-Z|a-z|0-9]\@([A-Z|a-z|0-9])+((\.){0,1}[A-Z|a-z|0-9]){2}\.[a-z]{2,3}$/,
                    "Enter valid email address"
                ),
            password: Yup.string().required("Enter Password")
        }),
        onSubmit: (values) => {
            handleFormSubmit(values);
        }
    });

    const handleFormSubmit = async (values) => {
        const { email, password } = values;

        try {
            setIsLoading(true);
            let res = await dispatch(
                login({
                    email: email,
                    password: password
                })
            );
            toast.success("Login successfully !");
            setTimeout(() => {
                <Redirect to={APP_ROUTES.DASHBOARD} />;
            }, 1200);
        }
        finally {
            setIsLoading(false);
        }
    };

    if (isAuthenticated) {
        return <Redirect to="/charter" />;
    }

    return (
        <React.Fragment>
            <MetaTags>
                <title>{METATAGS.LOGIN}</title>
            </MetaTags>
            <AuthWrapper
                leftContent={
                    <div className="text-center">
                        <h1 className="text-white fw-normal">
                            <span>WELCOME BACK</span>
                            <span className="d-block font-size-14">Please login to continue</span>
                        </h1>
                    </div>
                }
                data={
                    <div className="bv-login-wrap margin-top-vh">
                        <p className="login-type mb-4 ms-0">Login to Your Account</p>
                        <form className="" onSubmit={formik.handleSubmit}>
                            <div className="mb-4">
                                <TextInput
                                    placeholder="Email Address"
                                    name={"email"}
                                    type="email"
                                    className="hide-icon"
                                    inputGroupClassName={`${formik.touched.email && formik.errors.email && "border-danger"}`}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email}
                                    autoFocus
                                />
                                {formik.touched.email && formik.errors.email ? (
                                    <span className="error">{formik.errors.email}</span>
                                ) : null}
                            </div>

                            <div className="mb-2">
                                <TextInput
                                    placeholder="Password"
                                    type={!passwordFieldType ? "password" : "text"}
                                    className="hide-icon"
                                    inputGroupClassName={`${formik.touched.password && formik.errors.password && "border-danger"}`}
                                    name="password"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.password}
                                    iconFile={!passwordFieldType ? passIcon : showPassIcon}
                                    appendIcon={true}
                                    iconClickHandler={passwordFieldChangeHandler}
                                />
                                {formik.touched.password && formik.errors.password ? (
                                    <span className="error">{formik.errors.password}</span>
                                ) : null}
                            </div>
                            <div className="d-flex align-items-center justify-content-end ">
                                <Link
                                    className="mb-5 login-forget-pass-typo"
                                    to={APP_ROUTES.FORGET_PASSWORD}
                                >
                                    Forgot Password?
                                </Link>
                            </div>
                            <CustomButton
                                loading={isLoading}
                                type="submit"
                                color="primary"
                                title="Login Now"
                                className="w-100 login-btn-typo"
                            />
                        </form>
                    </div>
                }
                leftImgUrl={loginImage}
            />
        </React.Fragment>
    );
};

export default withRouter(Login);

Login.propTypes = {
    history: PropTypes.object
};
