import { TextInput } from "components/Input";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Form, FormGroup, Label } from "reactstrap";
import { useHistory } from "react-router-dom";

import "../../../pages/Authentication/AuthWrapper.scss";
import * as Yup from "yup";
import toast from "react-hot-toast";
import PhoneInput, { parsePhoneNumber, getCountryCallingCode } from "react-phone-number-input";
import {
    ForgetPasswordApi,
    ForgetPasswordVerification,
    ResendOtp,
    ResetPassword
} from "../../../api/api.service";
import { CustomButton } from "components/CustomButton";
import { useDispatch } from "react-redux";
import { CustomModal } from "components/CustomModal";
import { CUSTOMER_ROUTES, getRoute } from "helpers/routeHelpers";
import { CodeInput } from "components/CodeInput";
import PasswordLockIcon from "assets/images/customer/svg/PasswordLockIcon";
import passIcon from "assets/images/aiAPaiT/lock-icon.png";
import showPassIcon from "assets/images/aiAPaiT/show-lock-icon.svg";

const OTP_LENGTH = 4;
const RESEND_INTERVAL = 60; // 60 seconds

const ChangePassword = (props) => {
    const { userData } = { ...props };
    const dispatch = useDispatch();
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);
    const [isOTP, setIsOTP] = useState(false);
    const [holdToken, setHoldToken] = useState("");
    const [showOtpModal, setShowOtpModal] = useState(false);
    const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
    const [passwordVisibility, setPasswordVisibility] = useState({
        newPasswordVisible: false,
        confirmPasswordVisible: false
    });

    const [OTP, setOTP] = useState("");
    const [timer, setTimer] = useState(RESEND_INTERVAL);
    const [isResendCode, setIsResendCode] = useState(true);
    const [codeAndToken, setCodeAndToken] = useState({});
    const [resendToken, setResendToken] = useState("");

    // Formik setup
    const formik = useFormik({
        initialValues: {
            phoneNum: `${userData?.countryCode}${userData?.phone}`,
            new_password: "",
            confirm_password: ""
        },
        validationSchema: Yup.object().shape({
            phoneNum: Yup.string().required("Phone number is required"),
            // Password validation only applies when in reset password mode
            new_password: Yup.string().when([], {
                is: () => showResetPasswordModal,
                then: Yup.string()
                    .required("Please enter your new password")
                    .min(8, "Password should be at least 8 characters long")
                    .max(25, "Password should be no more than 25 characters long"),
                otherwise: Yup.string().notRequired()
            }),
            confirm_password: Yup.string().when([], {
                is: () => showResetPasswordModal,
                then: Yup.string()
                    .required("Please confirm your new password")
                    .oneOf([Yup.ref("new_password")], "Passwords must match"),
                otherwise: Yup.string().notRequired()
            })
        }),
        onSubmit: (values, { resetForm }) => {
            if (showResetPasswordModal) {
                // Call the reset password API
                handleResetPasswordHandler(values, resetForm);
            } else {
                // Call the Get OTP API
                handleGetOTP(values, resetForm);
            }
        }
    });

    // Start timer when modal opens, reset when modal closes
    useEffect(() => {
        let interval;
        if (showOtpModal) {
            setTimer(RESEND_INTERVAL); // Reset timer to 60 seconds when modal opens
            interval = setInterval(() => {
                setTimer((prevTimer) => {
                    if (prevTimer <= 0) {
                        clearInterval(interval);
                        setIsResendCode(false); // Allow user to resend OTP
                        return RESEND_INTERVAL;
                    }
                    return prevTimer - 1;
                });
            }, 1000);
        }

        return () => {
            clearInterval(interval); // Clear timer when modal is closed
        };
    }, [showOtpModal, isResendCode]);

    const handleResetPasswordHandler = async (values, resetForm) => {
        let { confirm_password } = values;
        try {
            setIsLoading(true);
            let body = {
                token: codeAndToken?.tokenUId,
                password: confirm_password
            };
            let res = await ResetPassword(body);
            toast.success("Password has been reset successfully !");

            setShowResetPasswordModal(false);
            setShowOtpModal(false);
            setOTP("");
            // Clear form values
            resetForm();
            history.push(getRoute(CUSTOMER_ROUTES?.SETTINGS));
        } catch (err) {
        } finally {
            setIsLoading(false);
        }
    };

    const handleGetOTP = async (data) => {
        const { phoneNum } = data;

        let countryCodeAlpha = parsePhoneNumber(phoneNum).country;
        let countryCode = getCountryCallingCode(countryCodeAlpha);
        countryCode = `+${countryCode}`;
        let phone = phoneNum.split(countryCode)[1];

        let userPhone = {
            countryCode: countryCode,
            phone: phone
        };
        try {
            setIsLoading(true);
            await ForgetPasswordApi(userPhone)
                .then(async (res) => {
                    setHoldToken(res?.token);
                    setShowOtpModal(true);
                    toast.success("OTP sent successfully !");
                    resetForm();
                })
                .catch((err) => {});
        } catch (err) {
        } finally {
            setIsLoading(false);
        }
    };

    const handleOTPVerify = async (e) => {
        e.preventDefault();
        try {
            setIsOTP(true);
            let body = {
                token: resendToken ? resendToken : holdToken,
                code: OTP
            };
            let res = await ForgetPasswordVerification(body);
            toast.success("OTP has been verified successfully!");

            let { token } = res;
            setCodeAndToken({ tokenUId: token });
            setShowOtpModal(false);
            setShowResetPasswordModal(true);
        } catch (err) {
        } finally {
            setTimeout(() => {
                setIsOTP(false);
            }, 1200);
        }
    };

    const handleResendCode = async () => {
        try {
            setIsResendCode(true); // Start timer
            let body = {
                token: holdToken,
                countryCode: userData?.countryCode,
                phone: userData?.phone
            };
            let res = await ResendOtp(body);
            setResendToken(res?.token);
            toast.success("An OTP has been sent to your phone number and email !");
        } catch (err) {
        } finally {
            setOTP(""); // Clear OTP input
        }
    };

    const togglePasswordVisibility = (field) => {
        setPasswordVisibility((prevState) => ({
            ...prevState,
            [field]: !prevState[field]
        }));
    };

    return (
        <React.Fragment>
            <Form onSubmit={formik.handleSubmit} className="profile-setting-form">
                <FormGroup row className="d-flex align-items-center">
                    <Col sm={2}>
                        <Label>Phone</Label>
                    </Col>
                    <Col sm={10}>
                        <PhoneInput
                            defaultCountry={"SA"}
                            countryOptionsOrder={["SA", "AE", "OM", "BH", "KW", "QA"]}
                            onChange={(values) => formik.setFieldValue("phoneNum", values)}
                            onBlur={() => formik.setFieldTouched("phoneNum")}
                            value={formik.values.phoneNum}
                            name="phoneNum"
                            placeholder="Phone Number"
                        />
                    </Col>
                </FormGroup>

                <FormGroup>
                    <Col className="text-end">
                        <CustomButton
                            loading={isLoading}
                            disabled={isLoading}
                            type="submit"
                            color="primary"
                            title="Get OTP"
                            className="mb-5 w-50"
                        />
                    </Col>
                </FormGroup>
            </Form>

            <CustomModal
                isOpen={showOtpModal}
                className="reset-pass-modal-con"
                data={
                    <div className="confirmation-modal cs-auth-wrapper">
                        <div className="change-password-title">
                            <h2 className="text-center">{"Verification"}</h2>
                            <p>Enter the code sent to your provided Phone Number and Email</p>
                        </div>
                        <form className="text-center">
                            <CodeInput className="my-4 text-left" onChange={(e) => setOTP(e)} />
                            <CustomButton
                                disabled={OTP.length !== OTP_LENGTH}
                                loading={isOTP}
                                type="submit"
                                onClick={handleOTPVerify}
                                color="primary"
                                title="Verify"
                                className="w-100 fw-bold login-btn-typo"
                            />
                        </form>

                        <div
                            className={`d-flex mt-4 align-items-center justify-content-center ${
                                !isResendCode
                                    ? "cursor-pointer customer-resend-otp-typo"
                                    : "customer-resend-otp-typo"
                            }`}
                            onClick={!isResendCode ? handleResendCode : null}
                        >
                            {isResendCode ? `Resend OTP in (${timer} sec)` : "Resend Now"}
                        </div>
                    </div>
                }
            />

            <CustomModal
                isOpen={showResetPasswordModal}
                className="reset-pass-modal-con"
                data={
                    <div className="confirmation-modal customer-login-form ">
                        <div className="change-password-title">
                            <h1 className="">{"Create New Password"}</h1>
                            <p>Keep your password safe</p>
                        </div>
                        <form onSubmit={formik.handleSubmit} className="profile-setting-form mt-4">
                            <FormGroup>
                                <Label htmlFor="new_password">New Password</Label>
                                <TextInput
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.new_password}
                                    iconFile={
                                        passwordVisibility.newPasswordVisible
                                            ? showPassIcon
                                            : passIcon
                                    }
                                    appendIcon={true}
                                    iconClickHandler={() =>
                                        togglePasswordVisibility("newPasswordVisible")
                                    }
                                    preAppendIconFile={<PasswordLockIcon />}
                                    placeholder="New Password"
                                    type={
                                        passwordVisibility.newPasswordVisible ? "text" : "password"
                                    }
                                    className="hide-icon"
                                    inputGroupClassName={`${
                                        formik.touched.new_password &&
                                        formik.errors.new_password &&
                                        "border-danger"
                                    }`}
                                    id="new_password"
                                    name="new_password"
                                />
                                {formik.errors.new_password && formik.touched.new_password && (
                                    <span className="error">{formik.errors.new_password}</span>
                                )}
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="confirm_password">Confirm Password</Label>
                                <TextInput
                                    preAppendIconFile={<PasswordLockIcon />}
                                    placeholder="Confirm Password"
                                    type={
                                        passwordVisibility.confirmPasswordVisible
                                            ? "text"
                                            : "password"
                                    }
                                    className="hide-icon"
                                    inputGroupClassName={`${
                                        formik.touched.confirm_password &&
                                        formik.errors.confirm_password &&
                                        "border-danger"
                                    }`}
                                    id="confirm_password"
                                    name="confirm_password"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.confirm_password}
                                    iconFile={
                                        passwordVisibility.confirmPasswordVisible
                                            ? showPassIcon
                                            : passIcon
                                    }
                                    appendIcon={true}
                                    iconClickHandler={() =>
                                        togglePasswordVisibility("confirmPasswordVisible")
                                    }
                                />
                                {formik.errors.confirm_password &&
                                    formik.touched.confirm_password && (
                                        <span className="error">
                                            {formik.errors.confirm_password}
                                        </span>
                                    )}
                            </FormGroup>

                            <CustomButton
                                loading={isLoading}
                                disabled={isLoading}
                                type="submit"
                                color="primary"
                                title="Reset Password"
                                className="my-2 w-100"
                            />
                        </form>
                    </div>
                }
            />
        </React.Fragment>
    );
};

export default ChangePassword;
