import React from 'react'

const LeftArrowIcon = () => {
  return (
    <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.90779 0.325497C9.34336 0.768173 9.34336 1.50302 8.91633 1.95455L3.73222 7.34634H17.8983C18.5046 7.34634 19 7.85984 19 8.49729C19 9.13475 18.5046 9.64825 17.8983 9.64825H3.73222L8.92487 15.04C9.3519 15.4916 9.34336 16.2176 8.91633 16.6691C8.48076 17.1118 7.78898 17.1118 7.35341 16.6602L0.316001 9.31182C0.222055 9.20558 0.145189 9.09048 0.0854055 8.94882C0.0256216 8.80717 0 8.65666 0 8.50615C0 8.20513 0.111028 7.92182 0.316001 7.70048L7.35341 0.352058C7.7719 -0.108325 8.47222 -0.117179 8.90779 0.325497Z" fill="#704DE7"/>
    </svg>
  )
}

export default LeftArrowIcon