import React from 'react'

const LoaderIcon = () => {
  return (
  <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="19.6766" cy="23.6559" rx="9.43396" ry="9.4086" fill="#D9D9D9"/>
    <ellipse cx="49.0566" cy="11.5591" rx="11.5903" ry="11.5591" fill="#D9D9D9"/>
    <ellipse cx="77.4932" cy="24.8655" rx="13.3423" ry="13.3065" fill="#D9D9D9"/>
    <ellipse cx="89.3532" cy="53.3602" rx="10.6469" ry="10.6183" fill="#D9D9D9"/>
    <ellipse cx="76.9542" cy="80.5107" rx="7.68194" ry="7.66129" fill="#D9D9D9"/>
    <ellipse cx="48.7872" cy="92.4729" rx="7.54717" ry="7.52688" fill="#D9D9D9"/>
    <ellipse cx="19.6765" cy="80.9141" rx="7.00809" ry="6.98925" fill="#D9D9D9"/>
    <ellipse cx="7.81671" cy="52.4192" rx="7.81671" ry="7.7957" fill="#D9D9D9"/>
  </svg>

  )
}

export default LoaderIcon;