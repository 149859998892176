import React from 'react'

const  TrademarkIcon = () => {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_20_232)">
        <path d="M0 9.49407C0 4.25269 4.22753 0.0119371 9.47014 2.48952e-05C14.7247 -0.0118873 19 4.25269 19 9.50598C19 14.7474 14.7128 19.0119 9.4582 19C4.21559 18.9881 0 14.7354 0 9.49407ZM1.55248 9.45833C1.54054 13.8301 5.06348 17.3919 9.43432 17.4276C13.7932 17.4633 17.4117 13.9016 17.4356 9.56554C17.4595 5.18184 13.9126 1.58435 9.50597 1.57244C5.15902 1.54861 1.56442 5.09846 1.55248 9.45833Z" fill="white"/>
        <path d="M14.3189 12.7937C14.3189 11.6263 14.3189 10.5304 14.3189 9.43449C14.2831 9.42257 14.2353 9.42257 14.1995 9.41066C13.8532 10.4113 13.483 11.4 13.1486 12.4006C13.0411 12.6984 12.9097 12.8533 12.5634 12.8414C12.241 12.8295 12.0738 12.7461 11.9663 12.4245C11.6319 11.4119 11.2617 10.4232 10.796 9.43449C10.796 10.5304 10.796 11.6263 10.796 12.7699C10.366 12.7699 9.98389 12.7699 9.56592 12.7699C9.56592 10.5781 9.56592 8.41004 9.56592 6.15863C9.97195 6.15863 10.378 6.11098 10.7482 6.18245C10.9154 6.21819 11.1065 6.45643 11.1662 6.64703C11.62 7.89781 12.0379 9.14859 12.5395 10.59C12.7187 10.0897 12.85 9.7442 12.9575 9.41066C13.268 8.52916 13.6024 7.65957 13.8651 6.76615C14.0084 6.25392 14.2711 6.11098 14.7608 6.17054C14.9996 6.19436 15.2385 6.17054 15.5251 6.17054C15.5251 8.39812 15.5251 10.5781 15.5251 12.8056C15.1429 12.7937 14.7846 12.7937 14.3189 12.7937Z" fill="white"/>
        <path d="M8.81343 6.2063C8.81343 6.55175 8.81343 6.86147 8.81343 7.25458C8.28798 7.25458 7.77446 7.2784 7.27289 7.24266C6.84297 7.21884 6.73549 7.38561 6.73549 7.79063C6.75938 9.232 6.74744 10.6853 6.74744 12.1267C6.74744 12.3411 6.74744 12.5555 6.74744 12.7938C6.31752 12.7938 5.97119 12.7938 5.54128 12.7938C5.52933 12.5913 5.50545 12.3887 5.50545 12.1743C5.50545 10.7091 5.49351 9.24392 5.51739 7.77871C5.52933 7.36179 5.38603 7.21884 4.96805 7.24266C4.47842 7.26649 3.97685 7.25458 3.43945 7.25458C3.43945 6.8853 3.43945 6.56367 3.43945 6.2063C5.21884 6.2063 6.97434 6.2063 8.81343 6.2063Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_20_232">
          <rect width="19" height="19" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default TrademarkIcon;