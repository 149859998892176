import React from 'react'

const UserProfileIcon = () => {
  return (
    <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.15439 11.1251H4.84544C3.34298 11.1251 2.12498 12.3431 2.12498 13.8455C2.12498 14.2196 2.36628 14.47 2.64121 14.5156C3.67846 14.6875 5.22007 14.875 6.9999 14.875C8.7797 14.875 10.3214 14.6875 11.3586 14.5156C11.6336 14.47 11.8748 14.2196 11.8748 13.8455C11.8748 12.3431 10.6568 11.1251 9.15439 11.1251ZM4.84544 10.0001C2.72166 10.0001 1 11.7218 1 13.8455C1 14.7216 1.59306 15.4822 2.45733 15.6254C3.53901 15.8047 5.14386 16 6.9999 16C8.85597 16 10.4608 15.8047 11.5425 15.6254C12.4067 15.4822 12.9998 14.7216 12.9998 13.8455C12.9998 11.7218 11.2781 10.0001 9.15439 10.0001H4.84544Z" fill="#704DE7" stroke="#704DE7" stroke-width="0.6" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M4.37474 4.74994C4.37474 6.19967 5.54997 7.3749 6.99969 7.3749C8.44942 7.3749 9.62465 6.19967 9.62465 4.74994C9.62465 3.30021 8.44942 2.12498 6.99969 2.12498C5.54997 2.12498 4.37474 3.30021 4.37474 4.74994ZM6.99969 1C4.92866 1 3.24976 2.67891 3.24976 4.74994C3.24976 6.82097 4.92866 8.49988 6.99969 8.49988C9.07071 8.49988 10.7496 6.82097 10.7496 4.74994C10.7496 2.67891 9.07071 1 6.99969 1Z" fill="#704DE7" stroke="#704DE7" stroke-width="0.6" />
    </svg>

  )
}

export default UserProfileIcon