import PaymentTabs from "components/customer/Payment/PaymentTabs";
import React from "react";

const Payment = () => {
    return (
        <div className=" settings-con p-4" >
            <PaymentTabs />
        </div>
    );
};

export default Payment;
