import React, { useState } from "react";
import "./TrademarkFilterModal.scss";
import { CustomModal } from "components/CustomModal";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { CustomButton } from "components/CustomButton";
import LeftArrowIcon from "assets/images/customer/svg/LeftArrowIcon";
import { useFormik } from "formik";

const TrademarkFilterModal = (props) => {
    const { ismodalOpen, handleApplyFilter, handleClose, handleSetFilter } = { ...props };

    const [selectedValue, setSelectedValue] = useState({
        name: "",
        value: ""
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setSelectedValue({ name, value });
    };

    const handleSubmit = () => {
        handleClose(false);

        let filterLabel;

        switch (selectedValue.name) {
            case "trademarkStatus":
                switch (selectedValue.value) {
                    case "Open":
                        filterLabel = "Open";
                        break;
                    case "Closed":
                        filterLabel = "Closed";
                        break;
                    case "Completed":
                        filterLabel = "Completed";
                        break;
                }
                break;
            case "trademarkStepsStatus":
                switch (selectedValue.value) {
                    case "PendingAdmin":
                        filterLabel = "Awaiting";
                        break;
                    case "PendingCustomer":
                        filterLabel = "Pending";
                        break;
                    case "Completed":
                        filterLabel = "Downloaded";
                        break;
                    case "Rejected":
                        filterLabel = "Rejected";
                        break;
                }
                break;
            case "trademarkStep":
                switch (selectedValue.value) {
                    case "FILING":
                        filterLabel = "01 Document Submission";
                        break;
                    case "POAAPPLICATION":
                        filterLabel = "02 E Signature";
                        break;
                    case "PUBLICATION":
                        filterLabel = "03 Publication";
                        break;
                    case "REGISTRATION":
                        filterLabel = "04 Registration";
                        break;
                    case "TMCRDOWNLOAD":
                        filterLabel = "05 TMCR";
                        break;
                }
                break;
        }

        handleSetFilter({
            filterType: selectedValue.name,
            statusValue: selectedValue.value,
            filterLabel: filterLabel
        });
    };

    const handleReset = () => {
        setSelectedValue({ name: "", value: "" });
        handleClose(false);
        handleSetFilter({
            filterType: "",
            statusValue: "",
            filterLabel: ""
        });
    };

    return (
        <CustomModal
            isOpen={ismodalOpen}
            size="lg"
            className={"filter-modal "}
            data={
                <div className="p-4 ">
                    <div className="d-flex align-items-center">
                        <button
                            className="border-0  p-0  bg-white"
                            onClick={() => handleClose(false)}
                        >
                            <LeftArrowIcon />
                        </button>

                        <h2 className="m-0 px-2">Trademark Filter</h2>
                    </div>
                    <Form className="py-4">
                        <FormGroup>
                            <FormGroup check>
                                <h6>Case Status</h6>
                                <Row>
                                    <div className="col-md-4">
                                        <FormGroup check>
                                            <Input
                                                type="checkbox"
                                                class="btn-check"
                                                name="trademarkStatus"
                                                id="Open"
                                                value={"Open"}
                                                onChange={handleChange}
                                                checked={
                                                    selectedValue.value === "Open" &&
                                                    selectedValue.name === "trademarkStatus"
                                                }
                                                autocomplete="off"
                                            />{" "}
                                            <label class="btn w-100" for="Open">
                                                Open
                                            </label>
                                        </FormGroup>
                                    </div>
                                    <div className="col-md-4">
                                        <FormGroup check>
                                            <Input
                                                type="checkbox"
                                                class="btn-check"
                                                name="trademarkStatus"
                                                value={"Completed"}
                                                id="Completed"
                                                onChange={handleChange}
                                                checked={
                                                    selectedValue.value === "Completed" &&
                                                    selectedValue.name === "trademarkStatus"
                                                }
                                                autocomplete="off"
                                            />
                                            <label class="btn w-100" for="Completed">
                                                Completed
                                            </label>
                                        </FormGroup>
                                    </div>
                                    <div className="col-md-4">
                                        <FormGroup check>
                                            <Input
                                                type="checkbox"
                                                class="btn-check"
                                                name="trademarkStatus"
                                                value={"Closed"}
                                                id="Closed"
                                                onChange={handleChange}
                                                checked={
                                                    selectedValue.value === "Closed" &&
                                                    selectedValue.name === "trademarkStatus"
                                                }
                                                autocomplete="off"
                                            />
                                            <label class="btn w-100" for="Closed">
                                                Closed
                                            </label>
                                        </FormGroup>
                                    </div>
                                </Row>

                                <div className="py-2">
                                    <h6>Response Status</h6>
                                    <Row>
                                        <div className="col-md-4">
                                            <FormGroup check>
                                                <Input
                                                    type="checkbox"
                                                    class="btn-check"
                                                    name="trademarkStepsStatus"
                                                    id="PendingAdmin"
                                                    value={"PendingAdmin"}
                                                    onChange={handleChange}
                                                    checked={
                                                        selectedValue.value === "PendingAdmin" &&
                                                        selectedValue.name ===
                                                            "trademarkStepsStatus"
                                                    }
                                                    autocomplete="off"
                                                />{" "}
                                                <label class="btn w-100" for="PendingAdmin">
                                                    Awaiting
                                                </label>
                                            </FormGroup>
                                        </div>
                                        <div className="col-md-4">
                                            <FormGroup check>
                                                <Input
                                                    type="checkbox"
                                                    class="btn-check"
                                                    name="trademarkStepsStatus"
                                                    value={"Completed"}
                                                    id="Complete"
                                                    onChange={handleChange}
                                                    checked={
                                                        selectedValue.value === "Completed" &&
                                                        selectedValue.name ===
                                                            "trademarkStepsStatus"
                                                    }
                                                    autocomplete="off"
                                                />
                                                <label class="btn w-100" for="Complete">
                                                    Downloaded
                                                </label>
                                            </FormGroup>
                                        </div>
                                        <div className="col-md-4">
                                            <FormGroup check>
                                                <Input
                                                    type="checkbox"
                                                    class="btn-check"
                                                    name="trademarkStepsStatus"
                                                    value={"PendingCustomer"}
                                                    id="PendingCustomer"
                                                    onChange={handleChange}
                                                    checked={
                                                        selectedValue.value === "PendingCustomer" &&
                                                        selectedValue.name ===
                                                            "trademarkStepsStatus"
                                                    }
                                                    autocomplete="off"
                                                />
                                                <label class="btn w-100" for="PendingCustomer">
                                                    Pending
                                                </label>
                                            </FormGroup>
                                        </div>

                                        <div className="col-md-4">
                                            <FormGroup check>
                                                <Input
                                                    type="checkbox"
                                                    class="btn-check"
                                                    name="trademarkStepsStatus"
                                                    value={"Rejected"}
                                                    id="Rejected"
                                                    onChange={handleChange}
                                                    checked={
                                                        selectedValue.value === "Rejected" &&
                                                        selectedValue.name ===
                                                            "trademarkStepsStatus"
                                                    }
                                                    autocomplete="off"
                                                />
                                                <label class="btn w-100" for="Rejected">
                                                    Rejected
                                                </label>
                                            </FormGroup>
                                        </div>
                                    </Row>
                                </div>

                                <div className="py-2">
                                    <h6>Process</h6>
                                    <Row>
                                        <div className="col-md-4">
                                            <FormGroup check>
                                                <Input
                                                    type="checkbox"
                                                    class="btn-check"
                                                    name="trademarkStep"
                                                    id="FILING"
                                                    value={"FILING"}
                                                    onChange={handleChange}
                                                    checked={
                                                        selectedValue.value === "FILING" &&
                                                        selectedValue.name === "trademarkStep"
                                                    }
                                                    autocomplete="off"
                                                />{" "}
                                                <label class="btn w-100" for="FILING">
                                                    01 Document Submission
                                                </label>
                                            </FormGroup>
                                        </div>
                                        <div className="col-md-4">
                                            <FormGroup check>
                                                <Input
                                                    type="checkbox"
                                                    class="btn-check"
                                                    name="trademarkStep"
                                                    value={"POAAPPLICATION"}
                                                    id="POAAPPLICATION"
                                                    onChange={handleChange}
                                                    checked={
                                                        selectedValue.value === "POAAPPLICATION" &&
                                                        selectedValue.name === "trademarkStep"
                                                    }
                                                    autocomplete="off"
                                                />
                                                <label class="btn w-100" for="POAAPPLICATION">
                                                    02 E Signature
                                                </label>
                                            </FormGroup>
                                        </div>
                                        <div className="col-md-4">
                                            <FormGroup check>
                                                <Input
                                                    type="checkbox"
                                                    class="btn-check"
                                                    name="trademarkStep"
                                                    value={"PUBLICATION"}
                                                    id="PUBLICATION"
                                                    onChange={handleChange}
                                                    checked={
                                                        selectedValue.value === "PUBLICATION" &&
                                                        selectedValue.name === "trademarkStep"
                                                    }
                                                    autocomplete="off"
                                                />
                                                <label class="btn w-100" for="PUBLICATION">
                                                    03 Publication
                                                </label>
                                            </FormGroup>
                                        </div>
                                        <div className="col-md-4">
                                            <FormGroup check>
                                                <Input
                                                    type="checkbox"
                                                    class="btn-check"
                                                    name="trademarkStep"
                                                    value={"REGISTRATION"}
                                                    id="REGISTRATION"
                                                    onChange={handleChange}
                                                    checked={
                                                        selectedValue.value === "REGISTRATION" &&
                                                        selectedValue.name === "trademarkStep"
                                                    }
                                                    autocomplete="off"
                                                />
                                                <label class="btn w-100" for="REGISTRATION">
                                                    04 Registration
                                                </label>
                                            </FormGroup>
                                        </div>
                                        <div className="col-md-4">
                                            <FormGroup check>
                                                <Input
                                                    type="checkbox"
                                                    class="btn-check"
                                                    name="trademarkStep"
                                                    value={"TMCRDOWNLOAD"}
                                                    id="TMCRDOWNLOAD"
                                                    onChange={handleChange}
                                                    checked={
                                                        selectedValue.value === "TMCRDOWNLOAD" &&
                                                        selectedValue.name === "trademarkStep"
                                                    }
                                                    autocomplete="off"
                                                />
                                                <label class="btn w-100" for="TMCRDOWNLOAD">
                                                    05 TMCR
                                                </label>
                                            </FormGroup>
                                        </div>
                                    </Row>
                                </div>
                            </FormGroup>
                        </FormGroup>
                        <FormGroup className="text-center">
                            <CustomButton
                                title={"Apply Filter"}
                                className="w-30 mx-2 shadow-none"
                                color="primary"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleSubmit();
                                }}
                                type={"submit"}
                            />
                            <CustomButton
                                title={"Reset Filter"}
                                className="w-30 mx-2 shadow-none"
                                color="primary"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleReset();
                                }}
                                type={"submit"}
                            />
                        </FormGroup>
                    </Form>
                </div>
            }
        />
    );
};

export default TrademarkFilterModal;
