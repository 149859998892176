import React, { useEffect, useState } from "react";
import "./Chats.scss";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import FilterIcon from "assets/images/customer/svg/FilterIcon";
import SearchIcon from "assets/images/customer/svg/SearchIcon";
import ChatCard from "components/customer/Chats/ChatCard";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CustomButton } from "components/CustomButton";
import { GetTrademarks } from "api/api.service";
import { Loader } from "components/Loader";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { CUSTOMER_ROUTES } from "helpers/routeHelpers";
import { Socket, SocketTypes } from "utils/socketUtils";
import { useLocalPaginatedRequest } from "../../../hooks/useLocalPaginatedRequest";
import { Pagination } from "../../../components/Pagination";

const Chats = () => {
    let [trademarks, setTrademarks] = useState();
    const history = useHistory();

    const { page, handlePageClick, pageSize, data, total, isFetching } = useLocalPaginatedRequest({
        requestFn: GetTrademarks,
        params: {
            ...{ filterColumns: "trademarkStatus" },
            ...{ filterColumnsValue: "Open" }
        },
        deps: ["Open"]
    });

    // useEffect(async () => {
    //     setLoading(true);

    //     const res = await GetTrademarks({
    //         page: page,
    //         column: "id",
    //         limit: 6,
    //         ...{ filterColumns: "trademarkStatus" },
    //         ...{ filterColumnsValue: "Open" },
    //         direction: "DESC"
    //     });

    //     setTrademarks(res?.data);
    //     setTotal(res?.total);
    //     setLoading(false);
    // }, [page]);

    const formik = useFormik({
        initialValues: {
            trademark_chat: ""
        },
        onSubmit: (values) => {
            handleFormSubmit(values);
        }
    });

    const handleFormSubmit = (values) => {
        const { trademark_chat } = values;

        Socket.onChatStarted(Number(trademark_chat), (data) => {
            if (data?.chatId) {
                // history.push(`${CUSTOMER_ROUTES.CHAT_DETAILS}/${data?.chatId}`);
                history.push({
                    pathname: `${CUSTOMER_ROUTES.CHAT_DETAILS}/${data?.chatId}`,
                    state: { trademarkId: trademark_chat }
                });
            }
        });
    };

    return (
        <React.Fragment>
            <div className="p-3">
                <Container fluid>
                    <Row className="greetings">
                        <h1>Chat And Call Support</h1>
                        <hr />
                    </Row>
                </Container>
                <Container fluid>
                    <Form className="chat-form" onSubmit={formik.handleSubmit}>
                        <FormGroup>
                            {data.length > 0 && !isFetching ? (
                                <Row>
                                    {data.map((item, idx) => {
                                        return (
                                            <Col md={6} key={idx} className="py-2">
                                                <FormGroup check>
                                                    <Input
                                                        name="trademark_chat"
                                                        id={item.id}
                                                        type="radio"
                                                        value={item.id}
                                                        onChange={formik.handleChange}
                                                    />

                                                    <Label htmlFor={item.id} check>
                                                        <ChatCard data={item} />
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                        );
                                    })}
                                    <FormGroup>
                                        <CustomButton
                                            title={"Start Chat"}
                                            className="w-100 shadow-none"
                                            color="primary"
                                            type={"submit"}
                                        />
                                    </FormGroup>
                                </Row>
                            ) : data.length === 0 && !isFetching ? (
                                <div className=" my-5 d-flex justify-content-center align-items-center">
                                    <h3 className="text-center">
                                        No active registered case found. Please register a case to
                                        start the chat.
                                    </h3>
                                </div>
                            ) : (
                                <Loader classes="vh-50" showVerifyText={false} />
                            )}
                        </FormGroup>
                    </Form>
                    {total > 10 ? (
                        <div className="d-flex justify-content-end align-items-center">
                            <Pagination
                                currentPage={page}
                                totalData={total}
                                onPageClick={handlePageClick}
                                className="pagination-contain "
                                rowLimit={pageSize}
                            />
                        </div>
                    ) : null}
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Chats;
