import React from "react";

import { CustomModal } from "components/CustomModal";
import { CustomButton } from "components/CustomButton";
import CrossIcon from "assets/images/customer/svg/CrossIcon";

const ConfirmationModal = (props) => {
    const { isOpen, content, onYes, onNo, onYesLoading } = { ...props };
    return (
        <CustomModal
            isOpen={isOpen}
            data={
                <div className="confirmation-modal">
                    <div className="confirmation-image">{<CrossIcon />}</div>
                    <div>
                        <h2>{content}</h2>
                    </div>

                    <div
                        className="d-flex justify-content-between align-items-center"
                        style={{ width: "80%" }}
                    >
                        <CustomButton
                            color=""
                            title="No"
                            outline={true}
                            onClick={onNo}
                            className="w-45 bv-btn-sm mt-3 me-4"
                        />
                        <CustomButton
                            color="primary"
                            title="Yes"
                            loading={onYesLoading}
                            onClick={onYes}
                            className="w-45 bv-btn-sm mt-3"
                        />
                    </div>
                </div>
            }
        />
    );
};

export default ConfirmationModal;
