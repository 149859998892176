import React from "react";

import madaLogo from "assets/images/aiAPaiT/payment-card-logos/mada.svg";
import amexLogo from "assets/images/aiAPaiT/payment-card-logos/amex.svg";
import visaMasterLogo from "assets/images/aiAPaiT/payment-card-logos/master_visa.svg";

import { CardTypes } from "constants";
import { CustomModal } from "components/CustomModal";
import { CustomButton } from "components/CustomButton";

// Map card types to their corresponding logos
const cardLogos = {
    [CardTypes.MADA]: madaLogo,
    [CardTypes.AMEX]: amexLogo,
    [CardTypes.VISA_MASTER]: visaMasterLogo
};

const PaymentModal = ({
    isOpen,
    amount,
    selectedCard,
    onCardSelect,
    isFillingLoader,
    onContinue,
    onCancel
}) => {
    return (
        <CustomModal
            isOpen={isOpen}
            data={
                <div className="p-4 payment-method-modal" style={{ minHeight: "300px" }}>
                    <div className="text-center">
                        <h2 className="m-0">
                            Pay{" "}
                            <span style={{ color: "#ad728b", fontSize: "2rem" }}>
                                ${amount?.toFixed(2) || 0}
                            </span>
                        </h2>
                        <p className="text-danger">* This amount is non-refundable</p>
                    </div>

                    <div className="d-flex justify-content-evenly align-items-center mt-3 flex-wrap">
                        {Object.keys(cardLogos).map((cardType) => (
                            <div
                                key={cardType}
                                className={`card-logo ${
                                    selectedCard === cardType ? "selected" : ""
                                }`}
                                onClick={() => onCardSelect(cardType)}
                                style={{ cursor: "pointer" }}
                            >
                                <img
                                    src={cardLogos[cardType]}
                                    alt={`${cardType}-logo`}
                                    style={{
                                        maxWidth: "80px",
                                        width: "80px",
                                        maxHeight: "50px",
                                        height: "50px"
                                    }}
                                />
                            </div>
                        ))}
                    </div>

                    <div className="text-center mt-4">
                        <p>Please select your preferable payment method</p>
                    </div>

                    <div className="d-flex justify-content-evenly align-items-center mt-4">
                        <CustomButton
                            color="primary"
                            title="Continue"
                            className="bv-btn-sm"
                            loading={isFillingLoader}
                            disabled={selectedCard == null}
                            onClick={onContinue}
                        />
                        <CustomButton
                            color=""
                            title="Cancel"
                            className="bv-btn-sm border"
                            onClick={onCancel}
                        />
                    </div>
                </div>
            }
        />
    );
};

export default PaymentModal;
