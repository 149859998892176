import { useLocation } from "react-router-dom";

export const useURLQuery = (key) => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);

    const value = query.get(key);

    return { [key]: value };
};
