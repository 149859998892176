import { injectBaseConstantMethods } from "./BaseConstants";

const Card_Types = {
    MADA: "MADA",
    AMEX: "AMEX",
    VISA_MASTER: "VISA_MASTER",
    China_UnionPay: "CHINA_UNIONPAY",
    Apple_Pay: "APPLE_PAY",
    Paypal: "PAYPAL"
};

const displayTextKeys = {
    [Card_Types.MADA]: "MADA",
    [Card_Types.AMEX]: "AMEX",
    [Card_Types.VISA_MASTER]: "VISA MASTER",
    [Card_Types.China_UnionPay]: "China UnionPay",
    [Card_Types.Apple_Pay]: "Apple Pay",
    [Card_Types.Paypal]: "Paypal"
};

const labelClass = {
    [Card_Types.MADA]: "Mada",
    [Card_Types.AMEX]: "Amex",
    [Card_Types.VISA_MASTER]: "Visa Master",
    [Card_Types.China_UnionPay]: "China UnionPay",
    [Card_Types.Apple_Pay]: "Apple Pay",
    [Card_Types.Paypal]: "Paypal"
};

export default injectBaseConstantMethods(Card_Types, displayTextKeys, labelClass);
