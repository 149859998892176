var Config = {
    LIMIT: 10,
    PERSIST_SECRET_KEY: "APP_REDUX_PERSIST_STORE_KEY",
    ENVIRONMENT: "DEVELOPMENT",
    ENVIRONMENTS: {
        LOCAL: {
            API_URL: "https://api.aiapait.kodefuse.com/v1",
            CHAT_URL: "https://api.aiapait.kodefuse.com",
            REACT_APP_IFRAME_ORIGIN: "http://localhost:3006"
        },
        DEVELOPMENT: {
            API_URL: "https://api.aiapait.kodefuse.com/v1",
            CHAT_URL: "https://api.aiapait.kodefuse.com",
            REACT_APP_IFRAME_ORIGIN: "https://dev-aiapaitapp.kodefuse.com"
        },
        QA: {
            API_URL: "https://qa-api.aiapait.kodefuse.com/v1",
            CHAT_URL: "https://qa-api.aiapait.kodefuse.com",
            REACT_APP_IFRAME_ORIGIN: "https://qa-aiapaitapp.kodefuse.com"
        },
        DEMO: {
            API_URL: "https://demo-api-aiapait.kodefuse.com/v1",
            CHAT_URL: "https://demo-api-aiapait.kodefuse.com"
        },
        PRODUCTION: {
            API_URL: "https://api.aiapait.com/v1",
            CHAT_URL: "https://api.aiapait.com",
            REACT_APP_IFRAME_ORIGIN: "https://app.aiapait.com"
        }
    }
};
Config.env = () => {
    return Config.ENVIRONMENTS[Config.ENVIRONMENT];
};
export default Config;
