import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import toast from "react-hot-toast";

import "./CaseManagement.scss";
import TradeMarkLogo from "../../assets/images/aiAPaiT/case-mangement/trademark.png";

import { CustomButton } from "components/CustomButton";
import { ESignById, TrademarkSignedDocument, GetTrademarkById } from "api/api.service";
import { useURLQuery } from "hooks/useURLQuery";
import { DOCUSIGN_EVENT_QUERY_PARAMS } from "constants/DocuSignEvents";
import { STEPS_NAME } from "constants/StepsName";
import { DOCUMENT_TYPES } from "constants/DocumentType";
import { CASE_STATUS } from "constants/CaseStatus";
import { CUSTOMER_ROUTES } from "helpers/routeHelpers";

const TrademarkDetail = (props) => {
    const {
        documentViewerHandler = () => {},
        markAsDownloaded = () => {},
        isMarkAsDownloaded = false,
        documentData,
        envelopeId,
        documentType,
        isDocumentSigned,
        documentID,
        isLegalisation,
        setESignStatus // Function passed from parent to enable the "Continue" button
    } = props;
    const history = useHistory();
    const { id } = useParams();

    const { event } = useURLQuery("event");
    const [isDocumentSign, setIsDocumentSign] = useState(false);
    const [signingCompleted, setSigningCompleted] = useState(false);

    // Effect to trigger signing completion handling
    useEffect(() => {
        if (
            event == DOCUSIGN_EVENT_QUERY_PARAMS.SigningComplete ||
            event == DOCUSIGN_EVENT_QUERY_PARAMS.ViewingComplete
        ) {
            verifyDocumentSigning();
        } else if (event == DOCUSIGN_EVENT_QUERY_PARAMS.Decline) {
            declineDocument();
        }
    }, [event]);

    const declineDocument = async () => {
        try {
            if (documentID) {
                let res = await TrademarkSignedDocument(
                    documentID,
                    STEPS_NAME.E_Signature,
                    CASE_STATUS.Close,
                    Number(id)
                );
                if (res?.message == "Updated") {
                    toast.error("Case has been closed.");
                    history.push(CUSTOMER_ROUTES.TRADEMARK);
                }
            }
        } catch (error) {
            toast.error("Failed to verify document signing.");
        }
    };

    // Function to verify if the document is signed via the API
    const verifyDocumentSigning = async () => {
        try {
            // Call TrademarkSignedDocument API to verify document signing status
            if (documentID) {
                const response = await TrademarkSignedDocument(documentID, STEPS_NAME.E_Signature);
                if (response.message == "Updated") {
                    // Fetch updated data to reflect the document's signed status
                    const updatedData = await GetTrademarkById(Number(id));
                    if (updatedData?.pOAApplication?.documents) {
                        const poaDocument = updatedData.pOAApplication.documents.find(
                            (doc) => doc.documentType == DOCUMENT_TYPES.PowerOfAttorney
                        );
                        if (poaDocument?.isSigned) {
                            setSigningCompleted(true);
                            setESignStatus(true); // Update parent state to enable the "Continue" button
                            toast.success("Document signed successfully.");
                        }
                    }
                }
            }
        } catch (error) {
            toast.error("Failed to verify document signing.");
        }
    };

    // Function to initiate signing via DocuSign
    const signPowerOfAttorney = async () => {
        setIsDocumentSign(true);
        try {
            const res = await ESignById(envelopeId);
            if (res && res.Url) {
                window.location.href = res.Url; // Redirect to DocuSign
            }
        } catch (error) {
            toast.error("Failed to sign the document.");
        } finally {
            setIsDocumentSign(false);
        }
    };

    const documentDownloader = (documentUrl) => {
        // adding this step for step 5 if shipping is not selected and we need to update api to mark trademark as completed
        if (isMarkAsDownloaded) {
            markAsDownloaded();
        }

        window.open(documentUrl, "_blank");
    };

    return (
        <div className="d-flex">
            <div className="case-detail-accordian-body">
                {documentData?.path.split(".").pop() !== "pdf" ? (
                    <div className="case-detail-accordian-body-img thumbnail-img-con">
                        <img src={documentData?.path} alt="trademark" />
                    </div>
                ) : (
                    <div className="case-detail-accordian-body-img">
                        <img src={TradeMarkLogo} alt="trademark" />
                    </div>
                )}
            </div>

            <div className="case-detail-trademark-con">
                <div className="case-detail-accordian-body-trademark-body">
                    <p>{documentData?.name || "-"}</p>
                    <div className="trademark-body-button-container justify-content-start">
                        <div
                            className="view-file-buttons me-3"
                            onClick={() => documentViewerHandler(documentData?.path)}
                        >
                            {"View"}
                        </div>
                        <div
                            className="view-file-buttons me-3"
                            onClick={() => documentDownloader(documentData?.path)}
                        >
                            {"Download"}
                        </div>
                        {/* <div
                            className="view-file-buttons me-3"
                            onClick={() => window.open(documentData?.path, "_blank")}
                        >
                            {"Download"}
                        </div> */}
                        {!isLegalisation && documentType === "PowerOfAttorney" && (
                            <CustomButton
                                type="button"
                                disabled={isDocumentSigned || signingCompleted}
                                onClick={signPowerOfAttorney}
                                color="primary"
                                title="Sign Power of Attorney"
                                loading={isDocumentSign}
                                className="view-file-buttons esign-button"
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

TrademarkDetail.propTypes = {
    documentViewerHandler: PropTypes.func.isRequired,
    documentData: PropTypes.object.isRequired,
    envelopeId: PropTypes.string,
    documentType: PropTypes.string,
    isDocumentSigned: PropTypes.bool,
    isMarkAsDownloaded: PropTypes.bool,
    setESignStatus: PropTypes.func,
    markAsDownloaded: PropTypes.func
};

export default TrademarkDetail;
