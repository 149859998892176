import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { withRouter, useHistory, Redirect, useLocation } from "react-router-dom";

import AuthWrapper from "./AuthWrapper";
import { CustomModal } from "../../../components/CustomModal";
import { CodeInput } from "../../../components/CodeInput";
import { CustomButton } from "../../../components/CustomButton";
import { METATAGS } from "../../../constants";
import { APP_ROUTES, CUSTOMER_ROUTES, getRoute } from "../../../helpers/routeHelpers";
import {
    ForgetPasswordVerification,
    RegisterVerifyCode,
    ResendOtp
} from "../../../api/api.service";
import Logo from "assets/images/customer/svg/Logo";
import { getMe, SET_TOKEN } from "store/actions";

const OTP_LENGTH = 4;
const RESEND_INTERVAL = 60; // 60 seconds

const RegisterVerfify = (props) => {
    let { tokenId } = props;

    const history = useHistory();
    const dispatch = useDispatch();

    const { state } = useLocation();

    const [isLoading, setIsLoading] = useState(false);
    const [OTP, setOTP] = useState("");
    const [codeAndToken, setCodeAndToken] = useState({});
    const [isResendCode, setIsResendCode] = useState(true);
    const [showOtpModal, setShowOtpModal] = useState(false);
    const [timer, setTimer] = useState(RESEND_INTERVAL);

    let isAuthenticated = useSelector((state) => state.userAuth.isAuthenticated);

    useEffect(() => {
        let interval;
        const savedTimer = localStorage.getItem("otpTimer");
        const lastTime = localStorage.getItem("lastTime");
        const timeElapsed = Math.floor((Date.now() - lastTime) / 1000);

        if (savedTimer && timeElapsed < RESEND_INTERVAL) {
            setTimer(Math.max(0, savedTimer - timeElapsed));
        }
        if (isResendCode) {
            interval = setInterval(() => {
                setTimer((prev) => {
                    const updatedTime = prev - 1;
                    localStorage.setItem("otpTimer", updatedTime);
                    localStorage.setItem("lastTime", Date.now());

                    if (updatedTime <= 0) {
                        clearInterval(interval);
                        setIsResendCode(false);
                        localStorage.removeItem("otpTimer");
                        localStorage.removeItem("lastTime");
                        return RESEND_INTERVAL;
                    }
                    return updatedTime;
                });
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [isResendCode]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            let body = {
                token: state?.tokenId,
                code: OTP
            };

            await RegisterVerifyCode(body)
                .then(async (res) => {
                    dispatch({ type: SET_TOKEN, token: res.token });
                    await dispatch(getMe(res.Token));
                    toast.success("Verification successful!");

                    return Promise.resolve({});
                })
                .catch((err) => {
                    return Promise.reject(err);
                });
        } finally {
            setTimeout(() => {
                setIsLoading(false);
            }, 1200);
        }
    };

    const handleResendCode = async () => {
        try {
            setIsResendCode(true);
            let body = {
                token: state?.tokenId,
                countryCode: state?.phoneData?.countryCode,
                phone: state?.phoneData?.phone
            };
            let res = await ResendOtp(body);
            toast.success("An OTP has been sent to your phone number and email !");
            // Update the state with the new token
            history.replace({
                pathname: CUSTOMER_ROUTES.REGISTER_VERIFY,
                state: {
                    tokenId: res?.token // Ensure new token is updated
                }
            });
        } finally {
            setOTP("");
        }
    };

    if (isAuthenticated) {
        return <Redirect to={CUSTOMER_ROUTES.TRADEMARK} />;
    }
    return (
        <>
            <MetaTags>
                <title>{METATAGS.REGISTER_VERIFY}</title>
            </MetaTags>
            <AuthWrapper
                leftContent={
                    <div style={{ backgroundColor: "#704DE7" }} className="customer-login ">
                        <Logo />
                    </div>
                }
                data={
                    <div className="customer-login-form">
                        <div className="title">
                            <h1 className="">{"One Time Password"}</h1>
                            <p className="mb-4 ms-0 " style={{}}>
                                Enter code sent to your provided phone number and email
                            </p>
                        </div>

                        <form className="">
                            <div className="mb-4">
                                <CodeInput className="my-4 text-left" onChange={(e) => setOTP(e)} />
                            </div>

                            <CustomButton
                                loading={isLoading}
                                type="submit"
                                onClick={handleSubmit}
                                color="primary"
                                title="Verify"
                                className="w-100 fw-bold login-btn-typo"
                            />
                        </form>
                        <div
                            className={`d-flex mt-4 align-items-center justify-content-center  ${
                                !isResendCode
                                    ? "cursor-pointer customer-resend-otp-typo"
                                    : "customer-resend-otp-typo"
                            } `}
                            // onClick={handleResendCode}
                            onClick={!isResendCode ? handleResendCode : null}
                        >
                            {/* {isResendCode ? "Sending..." : "Resend OTP"} */}
                            {isResendCode ? `Resend OTP in (${timer} sec)` : "Resend Now"}
                        </div>
                    </div>
                }
            />
            <CustomModal
                isOpen={showOtpModal}
                data={
                    <div className="text-center">
                        <CustomButton
                            color="primary"
                            title="Continue"
                            className="bv-btn-sm"
                            onClick={() => {
                                history.push(CUSTOMER_ROUTES.LOGIN);
                            }}
                        />
                    </div>
                }
            />
        </>
    );
};

RegisterVerfify.propTypes = {
    history: PropTypes.object
};

export default RegisterVerfify;

// import React, { useState, useEffect } from "react";
// import PropTypes from "prop-types";
// import MetaTags from "react-meta-tags";
// import toast from "react-hot-toast";
// import { useDispatch, useSelector } from "react-redux";
// import { withRouter, useHistory, Redirect, useLocation } from "react-router-dom";

// import AuthWrapper from "./AuthWrapper";
// import { CustomModal } from "../../../components/CustomModal";
// import { CodeInput } from "../../../components/CodeInput";
// import { CustomButton } from "../../../components/CustomButton";
// import { METATAGS } from "../../../constants";
// import { CUSTOMER_ROUTES, getRoute } from "../../../helpers/routeHelpers";
// import { RegisterVerifyCode, ResendOtp } from "../../../api/api.service";
// import Logo from "assets/images/customer/svg/Logo";
// import { SET_TOKEN } from "store/actions";

// const OTP_LENGTH = 4;
// const RESEND_INTERVAL = 60; // 60 seconds

// const RegisterVerify = (props) => {
//     let { tokenId } = props;

//     const history = useHistory();
//     const dispatch = useDispatch();
//     const { state } = useLocation();

//     const [isLoading, setIsLoading] = useState(false);
//     const [OTP, setOTP] = useState("");
//     const [isResendCode, setIsResendCode] = useState(false);
//     const [showOtpModal, setShowOtpModal] = useState(false);
//     const [timer, setTimer] = useState(RESEND_INTERVAL);

//     let isAuthenticated = useSelector((state) => state.userAuth.isAuthenticated);

//     useEffect(() => {
//         let interval;

//         if (isResendCode) {
//             interval = setInterval(() => {
//                 setTimer((prev) => {
//                     if (prev > 0) {
//                         return prev - 1;
//                     } else {
//                         clearInterval(interval);
//                         setIsResendCode(false); // Only stop the resend state when the timer reaches zero
//                         return RESEND_INTERVAL;
//                     }
//                 });
//             }, 1000);
//         }

//         return () => clearInterval(interval);
//     }, [isResendCode]);

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         try {
//             setIsLoading(true);
//             const body = {
//                 token: state?.tokenId, // Always use the latest token from state
//                 code: OTP
//             };

//             await RegisterVerifyCode(body)
//                 .then(async (res) => {
//                     dispatch({ type: SET_TOKEN, token: res?.token });
//                     toast.success("Verification successful!");
//                     setTimeout(() => {
//                         history.push(CUSTOMER_ROUTES.LOGIN);
//                     }, 1200);
//                     return Promise.resolve({});
//                 })
//                 .catch((err) => {
//                     return Promise.reject(err);
//                 });
//         } catch (err) {
//         } finally {
//             setTimeout(() => {
//                 setIsLoading(false);
//             }, 1200);
//         }
//     };

//     const handleResendCode = async () => {
//         try {
//             setIsResendCode(true); // Trigger the resend state
//             setTimer(RESEND_INTERVAL); // Reset the timer when OTP is resent
//             let body = {
//                 token: state?.tokenId,
//                 countryCode: state?.phoneData?.countryCode,
//                 phone: state?.phoneData?.phone
//             };
//             let res = await ResendOtp(body);

//             // Update the state with the new token
//             history.replace({
//                 pathname: CUSTOMER_ROUTES.REGISTER_VERIFY,
//                 state: {
//                     tokenId: res?.token // Ensure new token is updated
//                 }
//             });

//             toast.success("An OTP has been sent to your phone number and email!");
//         } catch (error) {
//             setIsResendCode(false); // Reset the resend state in case of an error
//             toast.error("Failed to resend OTP. Please try again.");
//         }
//     };

//     if (isAuthenticated) {
//         return <Redirect to={CUSTOMER_ROUTES.TRADEMARK} />;
//     }
//     console.log("state", state);
//     return (
//         <>
//             <MetaTags>
//                 <title>{METATAGS.REGISTER_VERIFY}</title>
//             </MetaTags>
//             <AuthWrapper
//                 leftContent={
//                     <div style={{ backgroundColor: "#704DE7" }} className="customer-login ">
//                         <Logo />
//                     </div>
//                 }
//                 data={
//                     <div className="customer-login-form">
//                         <div className="title">
//                             <h1>{"One Time Password"}</h1>
//                             <p className="mb-4 ms-0">
//                                 Enter the code sent to your provided phone number and email
//                             </p>
//                         </div>

//                         <form onSubmit={handleSubmit}>
//                             <div className="mb-4">
//                                 <CodeInput className="my-4 text-left" onChange={(e) => setOTP(e)} />
//                             </div>

//                             <CustomButton
//                                 loading={isLoading}
//                                 type="submit"
//                                 disabled={OTP.length !== OTP_LENGTH}
//                                 color="primary"
//                                 title="Verify"
//                                 className="w-100 fw-bold login-btn-typo"
//                             />
//                         </form>
//                         <div
//                             className={`d-flex mt-4 align-items-center justify-content-center ${
//                                 !isResendCode
//                                     ? "cursor-pointer customer-resend-otp-typo"
//                                     : "customer-resend-otp-typo"
//                             } `}
//                             onClick={!isResendCode ? handleResendCode : null}
//                         >
//                             {isResendCode ? `Resend OTP in (${timer} sec)` : "Resend OTP"}
//                         </div>
//                     </div>
//                 }
//             />
//             <CustomModal
//                 isOpen={showOtpModal}
//                 data={
//                     <div className="text-center">
//                         <CustomButton
//                             color="primary"
//                             title="Continue"
//                             className="bv-btn-sm"
//                             onClick={() => {
//                                 history.push(CUSTOMER_ROUTES.LOGIN);
//                                 setShowOtpModal(false);
//                             }}
//                         />
//                     </div>
//                 }
//             />
//         </>
//     );
// };

// RegisterVerify.propTypes = {
//     history: PropTypes.object,
//     tokenId: PropTypes.string
// };

// export default RegisterVerify;
