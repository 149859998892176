import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { Redirect } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { withRouter, Link, useHistory } from "react-router-dom";
import PhoneInput, {
    isValidPhoneNumber,
    parsePhoneNumber,
    getCountryCallingCode
} from "react-phone-number-input";
import AuthWrapper from "./AuthWrapper";
import { METATAGS } from "../../../constants";
import { TextInput } from "../../../components/Input";
import { CustomButton } from "../../../components/CustomButton";
import { CUSTOMER_ROUTES } from "../../../helpers/routeHelpers";
import { getMe, login, SET_TOKEN } from "../../../store/actions";
import toast from "react-hot-toast";
import passIcon from "../../../assets/images/aiAPaiT/lock-icon.png";
import showPassIcon from "../../../assets/images/aiAPaiT/show-lock-icon.svg";
import "./AuthStyle.scss";
import Logo from "../../../assets/images/customer/svg/Logo";
import PasswordLockIcon from "../../../assets/images/customer/svg/PasswordLockIcon";
import { head } from "lodash";
import LoaderIcon from "assets/images/customer/svg/LoaderIcon";
import { CustomModal } from "components/CustomModal";
import shortLogo from "assets/images/customer/short-logo.png";
import { CodeInput } from "components/CodeInput";
import { RegisterVerifyCode, ResendOtp } from "api/api.service";

const OTP_LENGTH = 4;
const RESEND_INTERVAL = 60; // 60 seconds
const ACTIVE = "ACTIVE";

const CustomerLogin = (props) => {
    const history = useHistory();

    let {} = props;
    const dispatch = useDispatch();
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showOtpModal, setShowOtpModal] = useState(false);
    const [passwordFieldType, setPasswordFieldType] = useState(false);
    const [isResendCode, setIsResendCode] = useState(true);
    const [OTP, setOTP] = useState("");
    const [isOTP, setIsOTP] = useState(false);
    const [timer, setTimer] = useState(RESEND_INTERVAL);
    const [resendToken, setResendToken] = useState("");

    let isAuthenticated = useSelector((state) => state.userAuth.isAuthenticated);

    // Start timer when modal opens, reset when modal closes
    useEffect(() => {
        let interval;
        if (showOtpModal) {
            setTimer(RESEND_INTERVAL); // Reset timer to 60 seconds when modal opens
            interval = setInterval(() => {
                setTimer((prevTimer) => {
                    if (prevTimer <= 0) {
                        clearInterval(interval);
                        setIsResendCode(false); // Allow user to resend OTP
                        return RESEND_INTERVAL;
                    }
                    return prevTimer - 1;
                });
            }, 1000);
        }

        return () => {
            clearInterval(interval); // Clear timer when modal is closed
        };
    }, [showOtpModal, isResendCode]);

    const formik = useFormik({
        initialValues: {
            phoneNum: "",
            password: ""
        },
        validationSchema: Yup.object({
            phoneNum: Yup.string()
                .required("Enter Phone Number")
                .test("phoneNum", "Phone number is invalid", function (val) {
                    return val && isValidPhoneNumber(val);
                }),
            password: Yup.string().required("Enter Password")
        }),
        onSubmit: (values) => {
            handleFormSubmit(values);
        }
    });

    const handleFormSubmit = async (values) => {
        const { phoneNum, password } = values;

        let countryCodeAlpha = parsePhoneNumber(phoneNum).country;
        let countryCode = getCountryCallingCode(countryCodeAlpha);
        countryCode = `+${countryCode}`;
        let phone = phoneNum.split(countryCode)[1];

        try {
            setIsLoading(true);
            let res = await dispatch(
                login({
                    countryCode: countryCode,
                    phone: phone,
                    password: password
                })
            );

            setData(res);
            if (res.status == ACTIVE) {
                toast.success("Login successfully !");
            } else {
                setShowOtpModal(true);
            }
        } catch (err) {
        } finally {
            setIsLoading(false);
        }
    };

    const handleResendCode = async () => {
        try {
            setIsResendCode(true); // Start timer
            let body = {
                token: data?.token,
                countryCode: data?.user?.countryCode,
                phone: data?.user?.phone
            };
            let res = await ResendOtp(body);
            setResendToken(res?.token);
            toast.success("An OTP has been sent to your phone number and email !");
        } catch (err) {
        } finally {
            setOTP(""); // Clear OTP input
        }
    };

    const handleOTPVerify = async (e) => {
        e.preventDefault();
        try {
            setIsOTP(true);
            let body = {
                token: resendToken ? resendToken : data?.token,
                code: OTP
            };

            await RegisterVerifyCode(body)
                .then(async (res) => {
                    dispatch({ type: SET_TOKEN, token: res.token });
                    await dispatch(getMe(res.Token));
                    toast.success("Verification successful!");

                    return Promise.resolve({});
                })
                .catch((err) => {
                    return Promise.reject(err);
                });
        } catch (err) {
        } finally {
            setTimeout(() => {
                setIsOTP(false);
            }, 1200);
        }
    };

    const passwordFieldChangeHandler = () => {
        setPasswordFieldType(!passwordFieldType);
    };

    if (isAuthenticated) {
        return <Redirect to="/trademarks" />;
    }

    return (
        <React.Fragment>
            <MetaTags>
                <title>{METATAGS.LOGIN}</title>
            </MetaTags>

            <AuthWrapper
                leftContent={
                    <div style={{ backgroundColor: "#704DE7" }} className="customer-login ">
                        <Logo />
                    </div>
                }
                data={
                    <div className="customer-login-form">
                        <div className="title">
                            <h1 className="">{"Let’s Sign You In"}</h1>
                            <p className="mb-4 ms-0 " style={{}}>
                                Welcome Back
                            </p>
                        </div>

                        <form className="" onSubmit={formik.handleSubmit}>
                            <div className="mb-4">
                                <PhoneInput
                                    defaultCountry={"SA"}
                                    countryOptionsOrder={["SA", "AE", "OM", "BH", "KW", "QA"]}
                                    onChange={(values) => formik.setFieldValue("phoneNum", values)}
                                    onBlur={() => {
                                        formik.setFieldTouched("phoneNum");
                                    }}
                                    value={formik.values.phoneNum}
                                    name="phoneNum"
                                    placeholder="Phone Number"
                                    className="phone-num-con"
                                />
                                {formik.touched.phoneNum && formik.errors.phoneNum ? (
                                    <span className="error">{formik.errors.phoneNum}</span>
                                ) : null}
                            </div>

                            <div className="mb-2">
                                <TextInput
                                    preAppendIconFile={<PasswordLockIcon />}
                                    placeholder="Password"
                                    type={!passwordFieldType ? "password" : "text"}
                                    className="hide-icon"
                                    inputGroupClassName={`${
                                        formik.touched.password &&
                                        formik.errors.password &&
                                        "border-danger"
                                    }`}
                                    name="password"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.password}
                                    iconFile={!passwordFieldType ? passIcon : showPassIcon}
                                    appendIcon={true}
                                    iconClickHandler={passwordFieldChangeHandler}
                                />
                                {formik.touched.password && formik.errors.password ? (
                                    <span className="error">{formik.errors.password}</span>
                                ) : null}
                            </div>
                            <div className="d-flex align-items-center justify-content-end ">
                                <Link
                                    className="mb-5 login-forget-pass-typo"
                                    to={CUSTOMER_ROUTES.FORGET_PASSWORD}
                                >
                                    Forgot Password?
                                </Link>
                            </div>
                            <CustomButton
                                loading={isLoading}
                                type="submit"
                                color="primary"
                                title="Login"
                                className="w-100 login-btn-typo"
                            />
                            <div
                                className="d-flex align-items-center justify-content-center py-3"
                                style={{ fontSize: "1rem", color: "#8566E7" }}
                            >
                                <p style={{ fontWeight: "600" }}>New Here? </p>
                                <Link
                                    to={CUSTOMER_ROUTES.REGISTER}
                                    style={{ fontWeight: "600" }}
                                    className="px-1"
                                >
                                    Register Now
                                </Link>
                            </div>
                        </form>
                    </div>
                }
            />

            <CustomModal
                isOpen={showOtpModal}
                className="reset-pass-modal-con"
                data={
                    <div className="confirmation-modal cs-auth-wrapper">
                        <div className="change-password-title">
                            <h2 className="text-center">{"Verification"}</h2>
                            <p>Enter the code sent to your provided Phone Number and Email</p>
                        </div>
                        <form className="text-center">
                            <CodeInput className="my-4 text-left" onChange={(e) => setOTP(e)} />
                            <CustomButton
                                disabled={OTP.length !== OTP_LENGTH}
                                loading={isOTP}
                                type="submit"
                                onClick={handleOTPVerify}
                                color="primary"
                                title="Verify"
                                className="w-100 fw-bold login-btn-typo"
                            />
                        </form>

                        <div
                            className={`d-flex mt-4 align-items-center justify-content-center ${
                                !isResendCode
                                    ? "cursor-pointer customer-resend-otp-typo"
                                    : "customer-resend-otp-typo"
                            }`}
                            onClick={!isResendCode ? handleResendCode : null}
                        >
                            {isResendCode ? `Resend OTP in (${timer} sec)` : "Resend Now"}
                        </div>
                    </div>
                }
            />
        </React.Fragment>
    );
};

export default withRouter(CustomerLogin);

CustomerLogin.propTypes = {
    history: PropTypes.object
};
