import { useHistory, useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import "./ChatDetails.scss";
import { useEffect, useState } from "react";
import { CUSTOMER_ROUTES } from "helpers/routeHelpers";
import { getChatById, getChatListingById } from "api/api.service";
import { Socket } from "socket.io-client";
import Chat from "../../../pages/Chats/Chat";
import { METATAGS } from "../../../constants";
import MetaTags from "react-meta-tags";
import { Col, Container, Row } from "reactstrap";
import CustomerChatModule from "./CustomerChatModule";

const ChatDetails = () => {
    const params = useParams();

    const history = useHistory();
    const [data, setData] = useState();
    const [chat, setChat] = useState(params?.id && { ...params });
    const [ChatListing, setChatListing] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isSidebarToggleActive, setIsSidebarToggleActive] = useState(true);

    useEffect(async () => {
        setLoading(true);
        try {
            // let 
            let res2 = await getChatListingById(Number(params?.id));
            setChat(res2);
        } catch (err) {
            history.push(CUSTOMER_ROUTES.CHATS);
        } finally {
            setLoading(false);
        }

        return () => {
            setData([]);
        };
    }, [params?.id]);

    // const handleMessageSent = ({ message }) => {
    //     let obj = _.cloneDeep(chat);
    //     const itemToUpdate = obj?.find(
    //         (item) => item?.id == window.location.pathname.split("/")[2]
    //     );
    //     if (itemToUpdate?.lastEvent === null || !itemToUpdate?.lastEvent) {
    //         itemToUpdate.lastEvent = {};
    //         itemToUpdate.isLastEventReaded = true;
    //     }
    //     itemToUpdate.lastEvent.content = message;
    //     itemToUpdate.lastEvent.updatedAt = new Date();

    //     const updatedData = obj?.filter(
    //         (item) => item?.id != window.location.pathname.split("/")[2]
    //     );
    //     setChatListing([itemToUpdate, ...updatedData]);
    // };

    const toggleSidebar = () => {
        setIsSidebarToggleActive(!isSidebarToggleActive);
    };

    return (
        <>
            <MetaTags>
                <title>{METATAGS.CHAT}</title>
            </MetaTags>
            <Container fluid>
                <Row>
                    <Col className="px-4 pt-4 ">
                        <div className="col-12 col-md-12 col-lg-12">
                            {chat?.id ? (
                                <CustomerChatModule
                                    userData={chat}
                                    isSidebarToggleActive={isSidebarToggleActive}
                                    setIsSidebarToggleActive={setIsSidebarToggleActive}
                                    trademarkId={history?.location?.state?.trademarkId}
                                    // onMessageReceived={handleMessageSent}
                                />
                            ) : (
                                ""
                            )}
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default ChatDetails;
