export const APP_ROUTES = {
    // **** Auth
    LOGIN: "/admin/login",
    LOGOUT: "/admin/logout",
    FORGET_PASSWORD: "/admin/forgot-password",
    OTP_VERIFY_CODE: "/admin/forget-verify-code",
    RESET_PASSWORD: "/admin/reset-password",
    // REGISTER: "/admin/register",

    CHANGE_PASSWORD: "/change-password",

    //sidebar
    DASHBOARD: "/admin/dashboard",
    CASE_MANAGEMENT: "/admin/case-management",
    CASE_MANAGEMENT_DETAILS: "/admin/case-management/case-details",
    CUSTOMER_MANAGEMENT: "/admin/customer-management",
    CUSTOMER_MANAGEMENT_DETAILS: "/admin/customer-management/customer-details",
    SUB_ADMINS: "/admin/departments",
    SUB_ADMINS_DETAILS: "/admin/departments/details",
    EARNINGS: "/admin/earnings",
    EARNINGS_DETAILS: "/admin/earnings/details",
    COUNTRIES: "/admin/countries",
    // GOODS_SERVICES: "/admin/goods-services",
    CHATS: "/chats",
    SETTINGS: "/admin/settings",
    SETTINGS_DETAILS: "/admin/settings/edit-profile",
    SETTINGS_CHANGE_PASS: "/admin/settings/change-pass"
};

export const CUSTOMER_ROUTES = {
    // **** Auth
    LOGIN: "/login",
    LOGOUT: "/logout",
    FORGET_PASSWORD: "/forgot-password",
    OTP_VERIFY_CODE: "/forget-verify-code",
    RESET_PASSWORD: "/reset-password",
    REGISTER: "/register",
    REGISTER_VERIFY: "/register-verify",

    TRADEMARK: "/trademarks",
    ADD_TRADEMARK: "/trademarks/trademark-registration",
    TRADEMARK_PAYMENT: "/trademarks/trademark-payment",
    SETTINGS: "/settings",
    PROFILE: "/profile",
    PAYMENT: "/payment",
    PAYMENT_DETAILS: "/payment/details",
    CHATS: "/chats",
    CHAT_DETAILS: "/chats/chat-detail"
};

export const getRoute = (route, obj = {}) => {
    if (Object.keys(obj).length) {
        let objectKeys = Object.keys(obj);
        objectKeys?.forEach((item) => {
            route = route?.replace(new RegExp(/:([\d\w?])+/, "i"), (match) => {
                let formattedMatchedValue =
                    match[match.length - 1] === "?"
                        ? match.slice(1, match.length - 1)
                        : match.slice(1);
                return obj[formattedMatchedValue];
            });
        });
        return route;
    }
    return route;
};
