import React from 'react'

const EmailIcon = () => {
  return (
    <svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.375 1.78125C2.04844 1.78125 1.78125 2.04844 1.78125 2.375V3.19512L8.18262 8.44981C8.95078 9.08066 10.0529 9.08066 10.8211 8.44981L17.2188 3.19512V2.375C17.2188 2.04844 16.9516 1.78125 16.625 1.78125H2.375ZM1.78125 5.49961V11.875C1.78125 12.2016 2.04844 12.4688 2.375 12.4688H16.625C16.9516 12.4688 17.2188 12.2016 17.2188 11.875V5.49961L11.9492 9.82656C10.5242 10.9955 8.47207 10.9955 7.05078 9.82656L1.78125 5.49961ZM0 2.375C0 1.06504 1.06504 0 2.375 0H16.625C17.935 0 19 1.06504 19 2.375V11.875C19 13.185 17.935 14.25 16.625 14.25H2.375C1.06504 14.25 0 13.185 0 11.875V2.375Z" fill="#704DE6" />
    </svg>
  )
}

export default EmailIcon