export const DOCUMENT_TYPES = {
    Application: "Application",
    PowerOfAttorney: "PowerOfAttorney"
};

const displayTextKeys = {
    [DOCUMENT_TYPES.Application]: "Application",
    [DOCUMENT_TYPES.PowerOfAttorney]: "Power of Attorney"
};

export default {
    DOCUMENT_TYPES,
    displayTextKeys
};
