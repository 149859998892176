import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Col, Row, Container } from "reactstrap";
import { METATAGS } from "../../../constants";

import { GetPaymentStatus, GetTrademarkById, TMCRDownloadStep } from "../../../api/api.service";
import { Loader } from "components/Loader";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import AlertModal from "components/customer/common/AlertModal/AlertModal";
import SuccessIcon from "assets/images/customer/svg/SuccessIcon";
import { CUSTOMER_ROUTES } from "helpers/routeHelpers";
import CrossIcon from "assets/images/customer/svg/CrossIcon";

const TrademarkPayment = () => {
    const [paymentStatus, setPaymentStatus] = useState();
    const [isSuccessModal, setIsSuccessModal] = useState(false);

    const history = useHistory();

    let search = useLocation().search;
    let id = new URLSearchParams(search).get("id");
    let TMCR_Id = localStorage.getItem("TMCR_Id");
    let trademarkId;

    if (!TMCR_Id) {
        trademarkId = localStorage.getItem("trademarkId");
    } else {
        trademarkId = localStorage.getItem("trademarkId");
    }

    if (!id) {
        history.goBack();
    }

    useEffect(async () => {
        const res = await GetPaymentStatus(id);
        if (TMCR_Id) {
            let tmcrShipping = await TMCRDownloadStep(TMCR_Id);
            tmcrShipping ? localStorage.removeItem("TMCR_Id") : null;
        }

        setPaymentStatus(res);
        setTimeout(() => {
            setIsSuccessModal(true);
        }, 2000);
    }, [id]);

    return (
        <React.Fragment>
            <div className="dashboard-container add-trademark-container">
                <MetaTags>
                    <title>{METATAGS.ADD_TRADEMARK}</title>
                </MetaTags>
                <Container fluid className="mt-5">
                    <Row>
                        <h2 className="fw-bold text-center">Verifying Payment</h2>
                        <Loader classes="mt-5" showVerifyText={false} />
                    </Row>
                </Container>
            </div>
            <AlertModal
                isOpen={isSuccessModal}
                title={
                    paymentStatus?.PaymentStatus == "Paid"
                        ? "Payment Successful!"
                        : "Payment Unsuccessful!"
                }
                icon={paymentStatus?.PaymentStatus == "Paid" ? <SuccessIcon /> : <CrossIcon />}
                content={
                    paymentStatus?.PaymentStatus == "Paid"
                        ? "The case will be reviewed soon. We will notify you of the next steps."
                        : "There was an error with your payment, please try again."
                }
                btn_content={paymentStatus?.PaymentStatus == "Paid" ? "Done" : "Try Again"}
                isClose={() => {
                    paymentStatus?.PaymentStatus === "Paid"
                        ? history.push(CUSTOMER_ROUTES.TRADEMARK)
                        : TMCR_Id
                        ? history.push(`${CUSTOMER_ROUTES.ADD_TRADEMARK + "/" + TMCR_Id}`)
                        : trademarkId
                        ? history.push(`${CUSTOMER_ROUTES.ADD_TRADEMARK + "/" + trademarkId}`)
                        : history.push(`${CUSTOMER_ROUTES.ADD_TRADEMARK}`);
                }}
            />
        </React.Fragment>
    );
};

export default TrademarkPayment;
