import React from "react";
import "./PaymentCard.scss";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Col, Row } from "reactstrap";
import { timeInNotificationFormat, zoneFormatToDDMMYY } from "utils/dateFormatUtils";
import { CUSTOMER_ROUTES } from "helpers/routeHelpers";

const PaymentCard = (props) => {
    const { data } = { ...props };
    
    return (
        <Link to={`${CUSTOMER_ROUTES.PAYMENT_DETAILS}/${data.id}`}>
            <div className="notification-container">
                <Row>
                    <Col md={6} className="d-flex align-items-center">
                        <span>{data.caseTitle}</span>
                    </Col>
                    <Col md={6} className="text-end"></Col>
                    <Col md={6}>{zoneFormatToDDMMYY(data?.createdAt)}</Col>
                    <Col md={6} className="text-end">
                        <span>$ {data.earnings}</span>
                    </Col>
                </Row>
            </div>
        </Link>
    );
};

export default PaymentCard;
