import SettingsTabs from "components/customer/Settings/SettingsTabs";
import { METATAGS } from "../../../constants";
import MetaTags from "react-meta-tags";

import React from "react";

const Settings = () => {
    return (
        <React.Fragment>
            <MetaTags>{METATAGS.SETTINGS}</MetaTags>
            <div className="settings-con p-4">
                <SettingsTabs />
            </div>
        </React.Fragment>
    );
};

export default Settings;
