import React from 'react'

const ProfileIcon = () => {
  return (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
     <path d="M10.2171 9.54668C10.5315 9.6672 10.8293 9.77049 11.1189 9.89962C13.0879 10.7863 14.5026 12.2583 15.3547 14.2985C15.7849 15.3229 15.9917 16.3989 15.9917 17.5266C15.9917 17.8021 15.8345 17.9914 15.5946 17.9914C15.3464 18 15.1809 17.8193 15.1727 17.5266C15.1479 15.6242 14.5357 13.9627 13.3112 12.551C12.2854 11.3716 11.0279 10.5883 9.53048 10.2698C7.08167 9.75328 4.90587 10.3731 3.06926 12.1378C1.93586 13.231 1.23265 14.5912 0.943095 16.1665C0.860365 16.6141 0.852092 17.079 0.819 17.5352C0.794181 17.9054 0.479807 18.112 0.190251 17.9312C0.0578833 17.8451 -0.00830078 17.716 -0.00830078 17.5524C-0.00830078 16.5625 0.157159 15.6069 0.479807 14.6858C0.893457 13.5151 1.53048 12.4907 2.39087 11.6213C3.30918 10.6916 4.38467 10.0201 5.60907 9.62415C5.65871 9.60694 5.70835 9.58972 5.76626 9.5639C3.39191 8.24682 2.70525 5.55242 3.36709 3.4692C4.02066 1.47207 5.74971 0.0775251 7.80969 5.02381e-05C9.92758 -0.0774247 11.8304 1.24826 12.5336 3.23678C12.9555 4.44194 12.9721 5.65572 12.5336 6.85227C12.0951 8.05744 11.3092 8.93549 10.2171 9.54668ZM7.9917 9.24539C10.2089 9.24539 11.9958 7.3946 12.0289 5.11339C12.062 2.81497 10.3164 0.860882 7.99997 0.852274C5.72489 0.852274 3.98756 2.72889 3.96274 4.98427C3.9462 7.36017 5.74971 9.24539 7.9917 9.24539Z" fill="white"/>
    </svg>
  )
}

export default ProfileIcon