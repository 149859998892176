import { CustomModal } from "components/CustomModal";
import { CUSTOMER_ROUTES } from "helpers/routeHelpers";
import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "./TrademarkStatusModal.scss";
import shortLogo from "assets/images/customer/short-logo.png";
import { CustomButton } from "components/CustomButton";

const TrademarkStatusModal = (props) => {
    const { data, ismodalOpen, handleClose } = { ...props };

    const history = useHistory();

    const handleCloseModal = () => {
        history.push(data.redirect);
    };

    return (
        <CustomModal
            isOpen={ismodalOpen}
            className={""}
            data={
                <div className="p-4 trademark-status-modal">
                    <div>
                        <h5>{data.title}</h5>
                    </div>
                    <div className="py-2">{data.icon}</div>
                    <div>
                        <p>
                            <div className="text-container">{data.content}</div>
                            {data.status === "PendingCustomer" ? (
                                <img src={shortLogo} alt="Logo" />
                            ) : null}
                        </p>
                    </div>
                    {data.status == "PendingAdmin" ? (
                        <CustomButton
                            color="primary"
                            title="Done"
                            // type={"submit"}
                            onClick={handleClose}
                            className="w-45 bv-btn-sm mb-3"
                        />
                    ) : data.status == "PendingCustomer" ? (
                        <div
                            className="d-flex justify-content-between align-items-center"
                            style={{ width: "80%" }}
                        >
                            <CustomButton
                                color=""
                                title="Cancel"
                                outline={true}
                                // type={"submit"}
                                onClick={handleClose}
                                className="w-45 bv-btn-sm mb-3"
                            />
                            <CustomButton
                                color="primary"
                                title="Continue Application"
                                // type={"submit"}
                                onClick={handleCloseModal}
                                className="w-45 bv-btn-sm mb-3"
                            />
                        </div>
                    ) : data.status == "Rejected" ? (
                        <div className="d-flex justify-content-between align-items-center">
                            <CustomButton
                                color=""
                                title="Close Case"
                                outline={true}
                                // type={"submit"}
                                onClick={handleClose}
                                className="w-45 bv-btn-sm mb-3"
                            />
                        </div>
                    ) : null}
                </div>
            }
        />
    );
};

export default TrademarkStatusModal;
