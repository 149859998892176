import React from "react"

const CheckIcon = () => {

  return (
    <svg width="37" height="26" viewBox="0 0 37 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M34 3L13.8919 23L3 12.1667" stroke="white" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
}

export default CheckIcon