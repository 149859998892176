import React from "react";
import "./ChatCard.scss";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Col, Row } from "reactstrap";
import { CUSTOMER_ROUTES } from "helpers/routeHelpers";
import { stepsName } from "../../../constants";
import ResponseStatus, { RESPONSE_STATUS } from "constants/ResponseStatus";

const ChatCard = (props) => {
    const { data } = { ...props };

    return (
        <React.Fragment>
            <div className={"chat_card"}>
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <span>Case ID</span>
                    </div>
                    <div>
                        <span>{data.caseId}</span>
                    </div>
                </div>
                <hr />
                <h4>{data.caseTitle}</h4>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="process">
                        <span>Process :</span>
                        <span className={`process_value ${data.trademarkStep}`}>
                            {stepsName.getDisplayTextKey(data.trademarkStep)}
                        </span>
                    </div>
                    <div className="text-end status">
                        <span>Status : </span>
                        <span
                            className={`${data?.trademarkStepsStatus} status-rest`}
                        >
                            {ResponseStatus.getDisplayTextKey(data?.trademarkStepsStatus) || "-"}
                        </span>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ChatCard;
