import React from 'react'

const SuccessIcon = () => {
  return (
    <svg width="135" height="136" viewBox="0 0 135 136" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M67.4286 9.50563C83.0214 9.50563 97.6742 15.5677 108.696 26.5897C119.718 37.6117 125.78 52.2644 125.78 67.8573C125.78 83.4501 119.718 98.1029 108.696 109.125C97.6742 120.147 83.0214 126.209 67.4286 126.209C51.8357 126.209 37.183 120.147 26.161 109.125C15.139 98.1029 9.07692 83.4501 9.07692 67.8573C9.07692 52.2644 15.139 37.6117 26.161 26.5897C37.183 15.5677 51.8357 9.50563 67.4286 9.50563ZM67.4286 0.428711C30.1808 0.428711 0 30.6095 0 67.8573C0 105.105 30.1808 135.286 67.4286 135.286C104.676 135.286 134.857 105.105 134.857 67.8573C134.857 30.6095 104.676 0.428711 67.4286 0.428711Z" fill="#F34D4D"/>
      <path d="M67.4291 5C102.12 5 130.287 33.1665 130.287 67.8576C130.287 102.549 102.12 130.715 67.4291 130.715C32.738 130.715 4.57153 102.549 4.57153 67.8576C4.57153 33.1665 32.738 5 67.4291 5Z" stroke="#21B473" stroke-width="8" stroke-linecap="round"/>
      <path d="M93.0269 51.2539L59.8191 84.4617L41.8315 66.4741" stroke="#21B473" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default SuccessIcon