import React from 'react'

const  PasswordLockIcon = () => {
  return (
  <svg width="18" height="23" viewBox="0 0 18 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.375 9.35566V6.05566C3.375 4.59697 3.96763 3.19803 5.02252 2.16658C6.07742 1.13513 7.50816 0.555664 9 0.555664C10.4918 0.555664 11.9226 1.13513 12.9775 2.16658C14.0324 3.19803 14.625 4.59697 14.625 6.05566V9.35566H15.75C16.3467 9.35566 16.919 9.58745 17.341 10C17.7629 10.4126 18 10.9722 18 11.5557V20.3557C18 20.9391 17.7629 21.4987 17.341 21.9113C16.919 22.3239 16.3467 22.5557 15.75 22.5557H2.25C1.65326 22.5557 1.08097 22.3239 0.65901 21.9113C0.237053 21.4987 0 20.9391 0 20.3557V11.5557C0 10.9722 0.237053 10.4126 0.65901 10C1.08097 9.58745 1.65326 9.35566 2.25 9.35566H3.375ZM2.25 11.5557V20.3557H15.75V11.5557H2.25ZM5.625 9.35566H12.375V6.05566C12.375 5.18045 12.0194 4.34108 11.3865 3.72221C10.7536 3.10334 9.89511 2.75566 9 2.75566C8.10489 2.75566 7.24645 3.10334 6.61351 3.72221C5.98058 4.34108 5.625 5.18045 5.625 6.05566V9.35566ZM11.25 13.7557H13.5V18.1557H11.25V13.7557Z" fill="#704DE7"/>
  </svg>
  )
}

export default PasswordLockIcon